import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@material-ui/core/Button";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import axios from "axios";
import image from "assets/images/team-2.jpg";
import SuiAvatar from "components/SuiAvatar";
import authorsTableData from "layouts/tables/data/authorsTableData";
import Table from "examples/Table";
import Icon from "@mui/material/Icon";
import { useAuth } from "../../auth-context/auth.context";
function Tables(outlined) {
  const { columns, rows } = authorsTableData;
  const classes = styles();
  const [pname, setPName] = useState("");
  const [pnam, setpName] = useState("");
  const [file, setFile] = useState("");
  const [fname, setFName] = useState("");
  const [ncol, setNcol] = useState("");
  const [cols, setCols] = useState("");
  const [num, setNum] = useState(0);
  const [dtable, setTable] = useState([]);
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  let { user } = useAuth();
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("http://45.127.6.59/api/users/newtable", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setTable(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  const deletetable = (val) => {
    var result = confirm("Project " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`http://45.127.6.59/api/users/newtable/${val}`).then((res) => {
        console.log(res);
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };
  const newdfile = () => {
    const uploadData = new FormData();
    if (pnam == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else if (file == "") {
      setMes(undefined);
      return setError("You must Upload A File");
    } else {
      uploadData.append("pname", pnam);
      uploadData.append("file", file, file.name);
      uploadData.append("flag", 0);
      uploadData.append("user", user["username"]);
      fetch("http://45.127.6.59/api/users/newtable", {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status == 0) {
            {
              setMes("");
            }
            {
              setError("Project Name Already Exist");
            }
          }

          if (data.status == 1) {
            {
              setError("");
            }
            {
              setMes(" Successfully Added");
            }
            decrementNum();
          }

          if (data.status == -1) {
            {
              setMes("");
            }
            {
              setError("Unknown Error");
            }
          }
        })
        .catch((error) => console.error(error));
      setError(undefined);
      decrementNum();
      return setMes(mes);
    }
  };

  const newdf = () => {
    const uploadData = new FormData();
    uploadData.append("pname", pname);
    uploadData.append("fname", fname);
    uploadData.append("ncol", ncol);
    uploadData.append("cols", cols);
    uploadData.append("flag", 1);
    uploadData.append("user", user["username"]);

    fetch("http://45.127.6.59/api/users/newtable", {
      method: "POST",
      body: uploadData,
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status == 0) {
          {
            setMes("");
          }
          {
            setError("Project Name Already Exist");
          }
        }

        if (data.status == 1) {
          {
            setError("");
          }
          {
            setMes(" Successfully Added");
          }
          decrementNum();
        }

        if (data.status == -1) {
          {
            setMes("");
          }
          {
            setError("Unknown Error");
          }
        }
      })
      .catch((error) => console.error(error));
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid item xs={12} md={6} xl={12}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">CREATED TABLE</SuiTypography>
              </SuiBox>
              <SuiBox customClass={classes.tables_table}>
                <Table columns={columns} rows={rows} />
              </SuiBox>
              {error && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                </SuiBox>
              )}
              {mes && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                </SuiBox>
              )}
              <SuiBox customClass={classes.tables_table}>
                <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                  <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                    <table style={{ width: "100%" }}>
                      <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                        <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            TABLE
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            FILE
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            DATE
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            SELECT
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            DELETE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dtable.map((dlak, index) => {
                          return (
                            <tr
                              className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                              key={index}
                            >
                              <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                  <SuiBox mr={2}>
                                    <SuiAvatar
                                      src={image}
                                      alt={"name"}
                                      size="sm"
                                      variant="rounded"
                                    />
                                  </SuiBox>
                                  <SuiBox display="flex" flexDirection="column">
                                    <SuiTypography variant="button" fontWeight="medium">
                                      {dlak.pname}
                                    </SuiTypography>
                                  </SuiBox>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    {dlak.fname}
                                  </SuiTypography>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiTypography
                                  variant="caption"
                                  textColor="secondary"
                                  fontWeight="medium"
                                >
                                  {" "}
                                  {dlak.datecreate}
                                </SuiTypography>
                              </td>
                              <td>
                                <SuiTypography
                                  variant="caption"
                                  textColor="secondary"
                                  fontWeight="medium"
                                >
                                  {" "}
                                  <a href={"/#/tablerec/?project=" + dlak.pname}>SELECT</a>
                                </SuiTypography>
                              </td>
                              <td align="center">
                                <Icon
                                  className="cursor-pointer"
                                  color="error"
                                  fontSize="medium"
                                  onClick={() => deletetable(dlak.pname)}
                                >
                                  delete
                                </Icon>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </SuiBox>
            </Card>
          </SuiBox>
        </Grid>

        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={pname}
                        onChange={(event) => {
                          setPName(event.target.value);
                        }}
                        type="text"
                        placeholder="Project Name"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={fname}
                        onChange={(event) => {
                          setFName(event.target.value);
                        }}
                        type="text"
                        placeholder="File Name"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={ncol}
                        onChange={(event) => {
                          setNcol(event.target.value);
                        }}
                        type="text"
                        placeholder="No of Columns"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={cols}
                        onChange={(event) => {
                          setCols(event.target.value);
                        }}
                        type="text"
                        placeholder="Names, Comma Seperate"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={() => newdf()}
                        fullWidth
                      >
                        create table
                      </SuiButton>
                    </SuiBox>
                  </div>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <div>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={pnam}
                    onChange={(event) => {
                      setpName(event.target.value);
                    }}
                    type="text"
                    placeholder="Project Name"
                  />
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <input
                    id="file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(evt) => setFile(evt.target.files[0])}
                  />
                  <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span" fullWidth>
                      <Icon className="font-bold">add</Icon>
                      &nbsp; add file
                    </Button>
                  </label>
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "grey", textAlign: "center" }}>{file.name}</h6>
                  </SuiBox>
                </SuiBox>

                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => newdfile()}
                    fullWidth
                  >
                    upload
                  </SuiButton>
                </SuiBox>
              </div>
            </Grid>
            <Grid item xs={12} md={6} xl={4}></Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
