import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Invoice from "layouts/cleanfunction/components/Invoice";
import axios from "axios";
// const params = new URLSearchParams(document.location.search);
function Invoices() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [fname, setFname] = useState();
  const [cols, setCols] = useState([]);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/selectdlake/${project}`)
        .then((res) => (setFname(res.data.fname), setCols(res.data.cols)));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Data Frame
        </SuiTypography>
        <SuiButton variant="outlined" buttonColor="info" size="small">
          {fname}
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {cols.map((item, index) => (
            <p key={index}>
              {" "}
              <Invoice date={item} id={project} />
            </p>
          ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Invoices;
