import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import image from "assets/images/project.png";
import SuiAvatar from "components/SuiAvatar";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../auth-context/auth.context";
function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;
  const [project, setProject] = useState([]);
  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  let { user } = useAuth();
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("http://45.127.6.59/api/users/project", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setProject(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  const deletel_project = (val) => {
    var result = confirm("Project " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`http://45.127.6.59/api/users/project/${val}`).then((res) => {
        console.log(res);
        decrementNum();
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">ML MODELS</SuiTypography>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table columns={columns} rows={rows} />
            </SuiBox>
            {error && (
              <SuiBox mt={2} mb={2} textAlign="right">
                <h6 style={{ color: "red", textAlign: "right" }}>{error}</h6>
              </SuiBox>
            )}
            {mes && (
              <SuiBox mt={2} mb={2} textAlign="right">
                <h6 style={{ color: "green", textAlign: "right" }}>{mes}</h6>
              </SuiBox>
            )}
            <SuiBox customClass={classes.tables_table}>
              <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                  <table style={{ width: "100%" }}>
                    <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                      <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          PROJECT
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          ALGORITHM
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          DEPENDENT
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {project.map((project, index) => {
                        return (
                          <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root" key={index}>
                            <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                              <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                <SuiBox mr={2}>
                                  <SuiAvatar src={image} alt={"name"} size="sm" variant="rounded" />
                                </SuiBox>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography variant="button" fontWeight="medium">
                                    {project.pname}
                                  </SuiTypography>
                                  <SuiTypography variant="caption" textColor="secondary">
                                    {project.date}
                                  </SuiTypography>
                                  <SuiTypography variant="caption" textColor="secondary">
                                    {project.statusdl}
                                  </SuiTypography>
                                </SuiBox>
                              </SuiBox>
                            </td>

                            <td>
                              <SuiBox display="flex" flexDirection="column">
                                <SuiTypography
                                  variant="caption"
                                  fontWeight="medium"
                                  textColor="text"
                                >
                                  {project.algorithm}
                                </SuiTypography>
                                <SuiTypography variant="caption" textColor="secondary">
                                  Params :{project.parameter}
                                </SuiTypography>
                              </SuiBox>
                            </td>

                            <td>
                              <SuiTypography
                                variant="caption"
                                textColor="secondary"
                                fontWeight="medium"
                              >
                                {" "}
                                {project.dependent}
                              </SuiTypography>
                            </td>
                            <td align="left">
                              <a href={"/#/mlapp/?project=" + project.pname}>
                                <Icon className="cursor-pointer" color="success" fontSize="medium">
                                  rocket
                                </Icon>{" "}
                              </a>{" "}
                              <span>&nbsp;&nbsp;&nbsp;</span>
                              <a href={project.h5path}>
                                <Icon className="cursor-pointer" color="success" fontSize="medium">
                                  download
                                </Icon>{" "}
                              </a>{" "}
                              <span>&nbsp;&nbsp;&nbsp;</span>
                              <Icon
                                className="cursor-pointer"
                                color="error"
                                fontSize="medium"
                                onClick={() => deletel_project(project.pname)}
                              >
                                delete
                              </Icon>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
