import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import borders from "assets/theme/base/borders";
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import { useEffect, useState } from "react";
import axios from "axios";
import SuiInput from "components/SuiInput";
import { useAuth } from "auth-context/auth.context";
function PaymentMethod() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const { borderWidth, borderColor } = borders;
  const [cname_automl, setCName_AutoMl] = useState("");
  const [send, setSend] = useState("");
  const [num, setNum] = useState(0);
  const [result, setResult] = useState("");
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  const [clalgm, setDep] = useState("");
  const [algm, setAlgm] = useState("");
  const [img_data, setImg_data] = useState("");
  const [ncluster, setCountCluster] = useState("");
  const [raw, setRaw] = useState("");
  const [elbow, setElbow] = useState("");
  const [agg, setAgg] = useState("");
  const [aggplot, setAggplot] = useState("");
  let { user } = useAuth();
  useEffect(() => {
    if (num == 0) {
      axios.get(`http://45.127.6.59/api/users/infodw/${project}`, {
        params: {
          username: user["username"],
        },
      });
    }

    if (num < 1) {
      incrementNum();
    }
    if (result == 10) {
      setError(""), setMes("Successfully Saved");
    } else if (result == -3) {
      setError("Error Saving"), setMes("");
    } else if (result == -5) {
      setError("Project Name Is Already Exist"), setMes("");
    } else if (result == 30) {
      setError("Django!!!!!!!!!!!!"), setMes("");
    }
  });

  const clustering = () => {
    if (cname_automl == "") {
      setMes(undefined);
      return setError("You Must Select Unsupervised Algm");
    }
    {
      axios.get(`http://45.127.6.59/api/users/clustering/${send}`).then((res) => {
        setResult(res.data.status),
          setDep(res.data.dep),
          setAlgm(res.data.algm),
          setImg_data(res.data.img_data),
          setElbow(res.data.elbow),
          setAgg(res.data.agg),
          setError(""),
          setMes("");
      });
      decrementNum();
    }
  };

  const clusteringPlot_ = () => {
    if (ncluster == "") {
      setMes(undefined);
      return setError("You Must Select Number of Cluster");
    }
    {
      axios.get(`http://45.127.6.59/api/users/clusteringAgg/${send}=${ncluster}`).then((res) => {
        setResult(res.data.status),
          setImg_data(res.data.img_data),
          setAggplot(res.data.aggplot),
          setError(""),
          setMes("");
      });
      decrementNum();
    }
  };

  const clustering_ = () => {
    setError("");
    setMes("");
    if (ncluster == "") {
      setMes(undefined);
      return setError("You Must Select Number of Cluster");
    }
    if (raw == "") {
      setMes(undefined);
      return setError("Project Name !!");
    }
    {
      axios
        .get(`http://45.127.6.59/api/users/clusteringC/${send}=${ncluster}=${raw}`, {
          params: {
            username: user["username"],
          },
        })
        .then((res) => {
          setResult(res.data.status), setError(""), setMes("");
        });
      decrementNum();
    }
  };

  const newproject = (algm, ncluster) => {
    const uploadData = new FormData();
    if (ncluster == "") {
      setMes(undefined);
      return setError("Select Cluster Count");
    } else if (raw == "") {
      setMes(undefined);
      return setError("Fill Model Name");
    } else {
      try {
        uploadData.append("project", project);
        uploadData.append("dependent", "cluser");
        uploadData.append("algorithm", algm);
        uploadData.append("parameter", ncluster);
        uploadData.append("score", 0);
        uploadData.append("modelname", raw);

        fetch("http://45.127.6.59/api/users/project", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 0) {
              setMes(undefined);
              return setError("Model Name Already Exist !!!");
            }
            if (data.status == 1) {
              window.location.reload();
            }
            if (data.status == -1) {
              setMes(undefined);
              return setError("Space Not A Name !!!");
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Unsupervised learning - Clustering
        </SuiTypography>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}

        <SuiButton variant="gradient" buttonColor="dark" onClick={() => clustering()}>
          <Icon className="font-bold">code</Icon>&nbsp;cluster
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <SuiBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2} />
              <select
                name="dtcol"
                id="dtcol"
                style={{
                  width: "50%",
                  height: 35,
                  color: "#525557",
                  backgroundColor: "#e4e9eb",
                  border: "3px solid #e4e9eb",
                }}
                onChange={(event) => {
                  setCName_AutoMl(event.target.value),
                    setSend(String(project) + "=" + String(event.target.value));
                }}
              >
                <option value="">--SELECT--</option>
                <option value="KMeans">KMeans</option>
                <option value="AgglomerativeClustering">AgglomerativeClustering</option>
              </select>
            </SuiBox>
            <p></p>

            {cname_automl && (
              <SuiTypography variant="caption" textColor="secondary">
                Clustering Algorithm : {cname_automl}
              </SuiTypography>
            )}
            <span>&nbsp;&nbsp;&nbsp;</span>
            <p></p>
            {result && (
              <SuiBox style={{ width: "100%", height: 35 }}>
                <SuiInput
                  onChange={(event) => {
                    setRaw(event.target.value);
                  }}
                  type="text"
                  placeholder="File Name"
                />
              </SuiBox>
            )}
            <span>
              <br></br>
            </span>
            {result && (
              <SuiButton variant="gradient" buttonColor="success" onClick={() => clustering_(algm)}>
                <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;save cluster data
              </SuiButton>
            )}
            <p></p>
            {result && (
              <p>
                <br></br>
              </p>
            )}
            {result && (
              <SuiButton
                variant="gradient"
                buttonColor="success"
                onClick={() => newproject(clalgm, ncluster)}
              >
                <Icon className="font-bold">house</Icon>&nbsp;save cluster model
              </SuiButton>
            )}
            <p></p>
            {result && (
              <p>
                <br></br>
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <SuiBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              {!result && (
                <SuiBox component="img" src={visaLogo} alt="master card" width="10%" mr={2} />
              )}
              <SuiTypography variant="h6" fontWeight="medium">
                {result && <SuiTypography variant="caption">CLUSTER</SuiTypography>}

                <p></p>
                {result && (
                  <SuiTypography variant="caption">Unsupervised Algm : {clalgm}</SuiTypography>
                )}
                <p></p>
                {result && <SuiTypography variant="caption">Data : {algm}</SuiTypography>}
                <p></p>
                {elbow && <SuiTypography variant="caption">method : ELBOW</SuiTypography>}
                {result && (
                  <p>
                    <br></br>
                  </p>
                )}
                {elbow && <img width="100%" src={`data:image/jpeg;base64,${img_data}`} />}
                {aggplot && <img width="100%" src={`data:image/jpeg;base64,${img_data}`} />}

                {result && (
                  <select
                    name="dtcol"
                    id="dtcol"
                    style={{
                      width: "100%",
                      height: 35,
                      color: "#525557",
                      backgroundColor: "#e4e9eb",
                      border: "3px solid #e4e9eb",
                    }}
                    onChange={(event) => {
                      setCountCluster(event.target.value);
                    }}
                  >
                    <option value="">--N Cluster--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </select>
                )}

                {result && (
                  <p>
                    <br></br>
                  </p>
                )}

                {ncluster && (
                  <SuiTypography variant="caption">No of Clusters:{ncluster}</SuiTypography>
                )}
                {result && (
                  <p>
                    <br></br>
                  </p>
                )}
                {agg && (
                  <SuiButton
                    style={{ width: "100%", height: 35 }}
                    variant="gradient"
                    buttonColor="primary"
                    onClick={() => clusteringPlot_()}
                  >
                    plot
                  </SuiButton>
                )}
                <p>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </p>
              </SuiTypography>

              <SuiBox ml="auto" lineHeight={0}></SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PaymentMethod;
