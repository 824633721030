import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import SuiTypography from "components/SuiTypography";
function Tables(outlined) {
  const params = new URLSearchParams(document.location.search);
  const project = params.get("project");
  const classes = styles();
  const [num, setNum] = useState(0);
  const [df, setDf] = useState([]);
  const [fl, setFl] = useState("");
  const [pname, setpName] = useState("");
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  const [dic, setDic] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/tablerec/${project}`)
        .then((res) =>
          console.log(
            setDf(res.data.df),
            setFl(res.data.fl),
            setDic(res.data.dcols),
            setColumns(res.data.dcols)
          )
        )
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  const [formFields, setFormFields] = useState([dic]);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    const uploadData = new FormData();
    for (let i = 0; i < columns.length; i++) {
      uploadData.append(i, formFields[0][columns[i]]);
    }

    uploadData.append("pname", project);
    fetch("http://45.127.6.59/api/users/tablerec", {
      method: "POST",
      body: uploadData,
    }).then((res) =>
      res.json().then((data) => {
        if (data.status == 1) {
          {
            setError("");
          }
        }
        {
          setMes("New Row Added");
        }
        if (data.status == 0) {
          {
            setError("Error");
          }
          {
            setMes("");
          }
        }
      })
    );

    decrementNum();
  };

  const newdf = () => {
    const uploadData = new FormData();
    if (pname == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else {
      try {
        uploadData.append("pname", pname);
        uploadData.append("project", project);
        fetch("http://45.127.6.59/api/users/tbllak", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 0) {
              {
                setMes("");
              }
              {
                setError("Project Name Already Exist");
              }
            } else if (data.status == 1) {
              {
                setError("");
              }
              {
                setMes(" Successfully Moved");
              }
              decrementNum();
            } else if (data.status == -1) {
              {
                setError("");
              }
              {
                setMes(" Error");
              }
              decrementNum();
            }

            if (data.status == -1) {
              {
                setMes("");
              }
              {
                setError("Unknown Error");
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SuiBox py={3}>
        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={8}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiTypography variant="caption" textColor="secondary">
                      Project Name :
                    </SuiTypography>
                    <SuiTypography variant="caption" textColor="dark">
                      <span>&nbsp;&nbsp;&nbsp;</span> {project}
                    </SuiTypography>
                    <form onSubmit={submit}>
                      {formFields.map((form, index) => {
                        return (
                          <div key={index}>
                            {columns.map((item, ind) => (
                              <SuiBox mb={1} ml={0.5} key={ind}>
                                <SuiInput
                                  name={item}
                                  placeholder={item}
                                  onChange={(event) => handleFormChange(event, index)}
                                  value={form.name}
                                />
                              </SuiBox>
                            ))}
                          </div>
                        );
                      })}
                    </form>
                  </div>
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton variant="gradient" buttonColor="dark" onClick={submit} fullWidth>
                    add record
                  </SuiButton>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              {error && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                </SuiBox>
              )}
              {mes && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                </SuiBox>
              )}
              <div>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={pname}
                    onChange={(event) => {
                      setpName(event.target.value);
                    }}
                    type="text"
                    placeholder="Project Name"
                  />
                </SuiBox>

                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="primary"
                    onClick={() => newdf()}
                    fullWidth
                  >
                    save to raw data
                  </SuiButton>
                </SuiBox>
              </div>
            </Grid>
          </Grid>
        </SuiBox>

        {fl && (
          <iframe
            className="responsive-iframe"
            srcDoc={df}
            width="100%"
            height="300pt"
            style={{
              backgroundImage:
                "url(" +
                "https://img.freepik.com/free-vector/watercolor-with-blue-detailed-background_1409-1170.jpg?w=1200&t=st=1707896022~exp=1707896622~hmac=fb06c2d711439b3a873b382324c9a014cf2b462ebf6929e3e2dd8ff59ac0b89c" +
                ")",
              background: "#2f5fa",
            }}
          ></iframe>
        )}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
