import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import typography from "assets/theme/base/typography";
import CreditCard from "examples/Icons/CreditCard";
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import Cube from "examples/Icons/Cube";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../auth-context/auth.context";

function Dashboard() {
  const [nlake, setNlake] = useState();
  const [nwh, setNwh] = useState();
  const [npr, setNpr] = useState();
  const [months, setMonths] = useState();
  const [cdl, setCdl] = useState();
  const [cwh, setCwh] = useState();
  const [cpr, setCpr] = useState();
  const [usern, setUser] = useState();
  let { user } = useAuth();
  const { size } = typography;
  const { chart, items } = {
    chart: {
      labels: ["Raw Data", "Proccessed Data", "Models", "App's"],
      datasets: { label: "Counts", data: [nlake, nwh, npr, npr] },
    },
    items: [
      {
        icon: { color: "primary", component: "images" },
        label: "un cleaned",
        progress: { content: String(nlake) + " No's", percentage: 100 },
      },
      {
        icon: { color: "dark", component: "computers" },
        label: "cleaned",
        progress: { content: String(nwh) + " No's", percentage: 100 },
      },
      {
        icon: { color: "warning", component: "speaker" },
        label: "model",
        progress: { content: String(npr) + " No's", percentage: 100 },
      },
      {
        icon: { color: "error", component: "table" },
        label: "deployed",
        progress: { content: String(npr) + " No's", percentage: 100 },
      },
    ],
  };
  const [num, setNum] = useState(0);
  const [gbdlake, setGbdlake] = useState("");
  const [gbwh, setGbwh] = useState("");
  const [gbdlake_, set_Gbdlake] = useState("");
  const [gbwh_, set_Gbwh] = useState("");
  const [projectcount, setProjectcount] = useState("");
  const [projectcount_, setProjectcount_] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/dash`, {
          params: {
            username: user["username"],
          },
        })
        .then(
          (res) => (
            setGbdlake(res.data["gblake"]),
            setGbwh(res.data["gbwh"]),
            set_Gbdlake(res.data["gblake_"]),
            set_Gbwh(res.data["gbwh_"]),
            setProjectcount(res.data["projectcount"]),
            setProjectcount_(res.data["projectcount_"]),
            setNpr(res.data["npr"]),
            setNwh(res.data["nwh"]),
            setNlake(res.data["nlake"]),
            setMonths(res.data["months"]),
            setCdl(res.data["cdl"]),
            setCwh(res.data["cwh"]),
            setCpr(res.data["cpr"]),
            setUser(user["username"])
          )
        )
        .catch((err) => console.log(err));
    }

    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <h6> {usern}</h6>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "RAW DATA" }}
                count={gbdlake}
                percentage={{ color: "success", text: "+" + gbdlake_ + "%" }}
                icon={{ color: "info", component: <Office size="20px" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "PROCESSED DATA" }}
                count={gbwh}
                percentage={{ color: "success", text: "+" + gbwh_ + "%" }}
                icon={{ color: "info", component: <Document size="20px" /> }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "MODELS" }}
                count={projectcount}
                percentage={{ color: "primary", text: "+" + projectcount_ }}
                icon={{ color: "info", component: <CreditCard size="20px" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "ML APPS" }}
                count={projectcount}
                percentage={{ color: "primary", text: "+" + projectcount_ }}
                icon={{
                  color: "info",
                  component: <Cube size="20px" />,
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title=" Mizu Usage"
                description={
                  <>
                    (<strong>+ PROJECTS Used</strong>) Used
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Project Usage"
                description={
                  <SuiBox display="flex" alignItems="center">
                    <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SuiBox>
                    <SuiTypography variant="button" textColor="text" fontWeight="medium">
                      54% Used
                      <SuiTypography
                        variant="button"
                        textColor="text"
                        fontWeight="regular"
                      ></SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                }
                height="20.25rem"
                chart={{
                  labels: months,
                  datasets: [
                    {
                      label: "Data Lake",
                      color: "info",
                      data: cdl,
                    },
                    {
                      label: "Data Warehouse",
                      color: "dark",
                      data: cwh,
                    },
                    {
                      label: "Project",
                      color: "warning",
                      data: cpr,
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
