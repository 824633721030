import PropTypes from "prop-types";
import clsx from "clsx";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import styles from "examples/Cards/PlaceholderCard/styles";
function PlaceholderCard({ hasBorder, outlined }) {
  const classes = styles({ hasBorder });
  return (
    <Card
      raised
      className={clsx(classes.placeholderCard, {
        [classes.placeholderCard_outlined]: outlined,
      })}
    >
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        height="100%"
        p={3}
      >
        New Table
        <SuiBox color="secondary" mb={0.5}>
          <div>
            <div className="file-search-button">
              <Tooltip title="Create New Table" placement="top">
                <Icon className="cursor-pointer" fontSize="large">
                  add
                </Icon>
              </Tooltip>
            </div>
          </div>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of PlaceholderCard
PlaceholderCard.defaultProps = {
  icon: "add",
  hasBorder: false,
  outlined: false,
};

// Typechecking props for the PlaceholderCard
PlaceholderCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  hasBorder: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default PlaceholderCard;
