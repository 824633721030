import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
// import Billing from "layouts/billing";
import Datastorage from "layouts/datastaorage";
import Warehouse from "layouts/tableswh";
import ProjectsMl from "layouts/tablesproj";
// import VirtualReality from "layouts/virtual-reality";
// import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";
import Dataclean from "layouts/dataclean";
import Cleanfunction from "layouts/cleanfunction";
import Dtypefunction from "layouts/dtypefunction";
import Dropfunction from "layouts/dropfunction";
import Fillnullfunction from "layouts/fillnullfunction";
import Encodefunction from "layouts/encodefunction";
import Regularexpr from "layouts/regularexpr";
import Datawarehouse from "layouts/datawarehouse";
import Dataploting from "layouts/dataploting";
import Algmtuning from "layouts/algmtuning";
import Algmtuning_reg from "layouts/algmtuning_reg";
import Clustering from "layouts/clustering";
import Mlapp from "layouts/mlapp";
import S3folder from "layouts/s3folder";
import S3toweb from "layouts/s3toweb";
import Infotxt from "layouts/infotxt";
import Compiler from "layouts/pythoncompiler";
import Newtable from "layouts/newtable";
import Tablerec from "layouts/tablerec";
import Captureimage from "layouts/captureimage";
import Capturevideo from "layouts/capturevideo";
import Captureaudio from "layouts/captureaudio";
import Adminsetting from "layouts/adminsetting";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import s2t from "layouts/s2t";
import t2s from "layouts/t2s";
import imgtotxt from "layouts/ocr";
import mergedf from "layouts/mergedf";
import MachineL from "layouts/MachineL";
import restaurant1 from "layouts/restaurant1";
import DomainSpecificL from "layouts/DomainSpecificL";
// import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
// import Projects from "layouts/dashboard/components/Projects";
import demoboj from "layouts/demoboj";
import demopathole from "layouts/demopathole";
import telegramreport from "layouts/telegramreport";
import restaurant1_dash from "layouts/restaurant1_dash";
import demopatholesetting from "layouts/demopatholesetting";

const routes = [
  {
    name: "s3toweb",
    key: "s3toweb",
    route: "/s3toweb",
    icon: <Shop size="12px" />,
    component: S3toweb,
    noCollapse: true,
    protected: true,
  },
  {
    name: "s3folder",
    key: "s3folder",
    route: "/s3folder",
    icon: <Shop size="12px" />,
    component: S3folder,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Infotxt",
    key: "infotxt",
    route: "/infotxt",
    icon: <Shop size="12px" />,
    component: Infotxt,
    noCollapse: true,
    protected: true,
  },
  {
    name: "mlapp",
    key: "mlapp",
    route: "/mlapp",
    icon: <Shop size="12px" />,
    component: Mlapp,
    noCollapse: true,
    protected: true,
  },

  {
    name: "algmtuning",
    key: "algmtuning",
    route: "/algmtuning",
    icon: <Shop size="12px" />,
    component: Algmtuning,
    noCollapse: true,
    protected: true,
  },
  {
    name: "clustering",
    key: "clustering",
    route: "/clustering",
    icon: <Shop size="12px" />,
    component: Clustering,
    noCollapse: true,
    protected: true,
  },
  {
    name: "algmtuning_reg",
    key: "algmtuning_reg",
    route: "/algmtuning_reg",
    icon: <Shop size="12px" />,
    component: Algmtuning_reg,
    noCollapse: true,
    protected: true,
  },
  {
    name: "dataploting",
    key: "dataploting",
    route: "/dataploting",
    icon: <Shop size="12px" />,
    component: Dataploting,
    noCollapse: true,
    protected: true,
  },
  {
    name: "datawarehouse",
    key: "datawarehouse",
    route: "/datawarehouse",
    icon: <Shop size="12px" />,
    component: Datawarehouse,
    noCollapse: true,
    protected: true,
  },
  {
    name: "regularexpr",
    key: "regularexpr",
    route: "/regularexpr",
    icon: <Shop size="12px" />,
    component: Regularexpr,
    noCollapse: true,
    protected: true,
  },
  {
    name: "encodefunction",
    key: "encodefunction",
    route: "/encodefunction",
    icon: <Shop size="12px" />,
    component: Encodefunction,
    noCollapse: true,
    protected: true,
  },

  {
    name: "fillnullfunction",
    key: "fillnullfunction",
    route: "/fillnullfunction",
    icon: <Shop size="12px" />,
    component: Fillnullfunction,
    noCollapse: true,
    protected: true,
  },

  {
    name: "dtypefunction",
    key: "dtypefunction",
    route: "/dtypefunction",
    icon: <Shop size="12px" />,
    component: Dtypefunction,
    noCollapse: true,
    protected: true,
  },

  {
    name: "dropfunction",
    key: "dropfunction",
    route: "/dropfunction",
    icon: <Shop size="12px" />,
    component: Dropfunction,
    noCollapse: true,
    protected: true,
  },
  {
    name: "cleanfunction",
    key: "cleanfunction",
    route: "/cleanfunction",
    icon: <Shop size="12px" />,
    component: Cleanfunction,
    noCollapse: true,
    protected: true,
  },
  {
    name: "dataclean",
    key: "dataclean",
    route: "/dataclean",
    icon: <Shop size="12px" />,
    component: Dataclean,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: Dashboard,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Raw Data",
    key: "tables",
    route: "/tables",
    icon: <Document size="12px" />,
    component: Tables,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Processed Data",
    key: "tableswh",
    route: "/tableswh",
    icon: <Office size="12px" />,
    component: Warehouse,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Models",
    key: "tablesproj",
    route: "/tablesproj",
    icon: <CreditCard size="12px" />,
    component: ProjectsMl,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Datastorage",
    key: "datastorage",
    route: "/datastorage",
    icon: <Cube size="12px" />,
    component: Datastorage,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Newtable",
    key: "newtable",
    route: "/newtable",
    icon: <Cube size="12px" />,
    component: Newtable,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Tablerec",
    key: "tablerec",
    route: "/tablerec",
    icon: <Cube size="12px" />,
    component: Tablerec,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Captureimage",
    key: "captureimage",
    route: "/captureimage",
    icon: <Cube size="12px" />,
    component: Captureimage,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Capturevideo",
    key: "capturevideo",
    route: "/capturevideo",
    icon: <Shop size="12px" />,
    component: Capturevideo,
    noCollapse: true,
    protected: true,
  },
  {
    name: "Captureaudio",
    key: "captureaudio",
    route: "/captureaudio",
    icon: <Shop size="12px" />,
    component: Captureaudio,
    noCollapse: true,
    protected: true,
  },

  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: Profile,
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "none",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: SignUp,
    noCollapse: true,
  },
  {
    type: "none",
    name: "pythoncompiler",
    key: "pythoncompiler",
    route: "/pythoncompiler",
    icon: <SpaceShip size="12px" />,
    component: Compiler,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "sign-out",
    route: "/authentication/sign-out",
    icon: <SpaceShip size="12px" />,
    component: SignOut,
    noCollapse: true,
  },
  {
    type: "Adminsetting",
    name: "Adminsetting",
    key: "sign-out",
    route: "/adminsetting",
    icon: <SpaceShip size="12px" />,
    component: Adminsetting,
    noCollapse: true,
  },
  {
    type: "s2t",
    name: "s2t",
    key: "s2t",
    route: "/s2t",
    icon: <SpaceShip size="12px" />,
    component: s2t,
    noCollapse: true,
  },
  {
    type: "t2s",
    name: "t2s",
    key: "t2s",
    route: "/t2s",
    icon: <SpaceShip size="12px" />,
    component: t2s,
    noCollapse: true,
  },
  {
    type: "MachineL",
    name: "MachineL",
    key: "MachineL",
    route: "/MachineL",
    icon: <SpaceShip size="12px" />,
    component: MachineL,
    noCollapse: true,
  },
  {
    type: "restaurant1",
    name: "restaurant1",
    key: "restaurant1",
    route: "/restaurant1",
    icon: <SpaceShip size="12px" />,
    component: restaurant1,
    noCollapse: true,
  },
  {
    type: "DomainSpecificL",
    name: "DomainSpecificL",
    key: "DomainSpecificL",
    route: "/DomainSpecificL",
    icon: <SpaceShip size="12px" />,
    component: DomainSpecificL,
    noCollapse: true,
  },
  {
    type: "mergedf",
    name: "mergedf",
    key: "mergedf",
    route: "/mergedf",
    icon: <SpaceShip size="12px" />,
    component: mergedf,
    noCollapse: true,
  },
  {
    type: "imgtotxt",
    name: "imgtotxt",
    key: "imgtotxt",
    route: "/ocr",
    icon: <SpaceShip size="12px" />,
    component: imgtotxt,
    noCollapse: true,
  },
  {
    type: "demoobj",
    name: "demoobj",
    key: "demoobj",
    route: "/demoobj",
    icon: <SpaceShip size="12px" />,
    component: demoboj,
    noCollapse: true,
  },
  {
    type: "demopathole",
    name: "demopathole",
    key: "demopathole",
    route: "/demopathole",
    icon: <SpaceShip size="12px" />,
    component: demopathole,
    noCollapse: true,
  },
  {
    type: "demopatholesetting",
    name: "demopatholesetting",
    key: "demopatholesetting",
    route: "/demopatholesetting",
    icon: <SpaceShip size="12px" />,
    component: demopatholesetting,
    noCollapse: true,
  },
  {
    type: "telegramreport",
    name: "telegramreport",
    key: "telegramreport",
    route: "/telegramreport",
    icon: <SpaceShip size="12px" />,
    component: telegramreport,
    noCollapse: true,
  },
  {
    type: "restaurant1_dash",
    name: "restaurant1_dash",
    key: "restaurant1_dash",
    route: "/restaurant1_dash",
    icon: <SpaceShip size="12px" />,
    component: restaurant1_dash,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "APPS",
    key: "DomainSpecificL",
    route: "/DomainSpecificL",
    icon: <Document size="12px" />,
    component: DomainSpecificL,
    noCollapse: true,
    protected: true,
  },
];

export default routes;
