// Images
import kal from "assets/images/small-logos/datawarehouse1.png";
import marie from "assets/images/project.png";
import plot from "assets/images/regression.jpg";
import ivana from "assets/images/small-logos/datawarehouse2.png";
export default [
  {
    image: kal,
    name: "DATA STORAGE",
    description: "data lake, data warehouses",
    action: {
      type: "internal",
      route: `/datastorage`,
      color: "info",
      label: "click",
    },
  },
  {
    image: marie,
    name: "MCHINE LEARNING",
    description: "algorithms,insights",
    action: {
      type: "internal",
      route: `/tableswh`,
      color: "info",
      label: "click",
    },
  },
  {
    image: ivana,
    name: "DATA PROCCESSING",
    description: "data engineering,cleaning",
    action: {
      type: "internal",
      route: `/tables`,
      color: "info",
      label: "click",
    },
  },
  {
    image: plot,
    name: "VISUALIZATION",
    description: "ploting,insights",
    action: {
      type: "internal",
      route: "/tableswh",
      color: "info",
      label: "click",
    },
  },
];
