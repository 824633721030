/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiAvatar from "components/SuiAvatar";
// import SuiBadge from "components/SuiBadge";

// // Images
// import team2 from "assets/images/team-2.jpg";

// function Author({ image, name, email }) {
//   return (
//     <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
//       <SuiBox mr={2}>
//         <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
//       </SuiBox>
//       <SuiBox display="flex" flexDirection="column">
//         <SuiTypography variant="button" fontWeight="medium">
//           {name}
//         </SuiTypography>
//         <SuiTypography variant="caption" textColor="secondary">
//           {email}
//         </SuiTypography>
//       </SuiBox>
//     </SuiBox>
//   );
// }

// function Function({ job, org }) {
//   return (
//     <SuiBox display="flex" flexDirection="column">
//       <SuiTypography variant="caption" fontWeight="medium" textColor="text">
//         {job}
//       </SuiTypography>
//       <SuiTypography variant="caption" textColor="secondary">
//         {org}
//       </SuiTypography>
//     </SuiBox>
//   );
// }

export default {
  columns: [
    { name: "", align: "left" },
    { name: "", align: "left" },
    { name: "", align: "left" },
    { name: "", align: "left" },
    { name: "", align: "left" },
  ],

  rows: [
    // {
    //   file: <Author image={team2} name="diabetes" email="CSV" />,
    //   description: <Function job="No of Columns:10" org="No of Rows: 10000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="processed" color="success" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       2 GB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
    // {
    //   file: <Author image={team2} name="Housing Price" email="CSV" />,
    //   description: <Function job="No of Columns:9" org="No of Rows: 20000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="not clean" color="secondary" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       100 MB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
    // {
    //   file: <Author image={team2} name="iris" email="XLX" />,
    //   description: <Function job="No of Columns:3" org="No of Rows: 12000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="processed" color="success" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       1 GB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
    // {
    //   file: <Author image={team2} name="debtdata" email="CSV" />,
    //   description: <Function job="No of Columns:5" org="No of Rows: 50000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="processed" color="success" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       2 GB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
    // {
    //   file: <Author image={team2} name="sales" email="csv" />,
    //   description: <Function job="No of Columns:1" org="No of Rows: 10000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="not clean" color="secondary" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       200 MB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
    // {
    //   file: <Author image={team2} name="mark" email="XLX" />,
    //   description: <Function job="No of Columns:2" org="No of Rows: 90000" />,
    //   status: (
    //     <SuiBadge variant="gradient" badgeContent="not clean" color="secondary" size="extra-small" />
    //   ),
    //   size: (
    //     <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
    //       1 GB
    //     </SuiTypography>
    //   ),
    //   action: (
    //     <SuiTypography
    //       component="a"
    //       href="#"
    //       variant="caption"
    //       textColor="secondary"
    //       fontWeight="medium"
    //     >
    //       Edit
    //     </SuiTypography>
    //   ),
    // },
  ],
};
