import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import styles from "layouts/dashboard/components/BuildByDevelopers/styles";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useEffect, useState } from "react";
import axios from "axios";
function BuildByDevelopers() {
  const classes = styles();
  const [num, setNum] = useState(0);
  const [proinfoh, setProinfoh] = useState("");
  const [proinfos, setProinfos] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios
        .get("http://45.127.6.59/api/users/infotxt")
        .then((res) => (setProinfoh(res.data[0].txt3), setProinfos(res.data[0].txt5)))
        .catch((err) => console.log(err));
    }

    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" flexDirection="column" height="100%">
              <SuiBox pt={1} mb={0.5}>
                <SuiTypography variant="body2" textColor="text" fontWeight="medium">
                  Project Idea
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="bold" gutterBottom>
                {proinfoh}
              </SuiTypography>
              <SuiBox mb={6}>
                <SuiTypography variant="body2" textColor="text">
                  {proinfos}
                </SuiTypography>
              </SuiBox>
              <SuiTypography
                component="a"
                href="/#/profile"
                variant="button"
                textColor="text"
                fontWeight="medium"
                customClass={classes.buildByDevelopers_button}
              >
                Read More
                <Icon className="font-bold">arrow_forward</Icon>
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={5} className="ml-auto relative">
            <SuiBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              backgroundColor="info"
              borderRadius="lg"
              backgroundGradient
            >
              <SuiBox
                component="img"
                src={wavesWhite}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"
              />
              <SuiBox component="img" src={rocketWhite} alt="rocket" width="100%" pt={3} />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BuildByDevelopers;
