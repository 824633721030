import PropTypes from "prop-types";
// @mui material components
import Icon from "@mui/material/Icon";
import axios from "axios";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useState } from "react";
function Bill({ name, company, email, vat, noGutter }) {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");

  const deletelcol = (val) => {
    var result = confirm("Column " + val + " Confirm To Delete?");
    console.log(val);
    if (result) {
      axios.get(`http://45.127.6.59/api/users/dropcolumn/${project + "=" + val}`).then((res) => {
        if (res.data.status == 1) {
          {
            setMes("Column " + val + " Successfully Deleted");
            window.location.reload();
          }
        } else {
          {
            setError("unknown Error, try Again");
          }
        }
      });
    }
  };

  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      backgroundColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <SuiBox width="100%" display="flex" flexDirection="column">
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}

        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <SuiTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </SuiTypography>

          <SuiBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <SuiBox mr={1}>
              <SuiButton variant="text" buttonColor="error">
                <Icon
                  className="cursor-pointer"
                  color="error"
                  fontSize="medium"
                  onClick={() => deletelcol(name)}
                >
                  delete
                </Icon>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" textColor="text">
            Nulls:&nbsp;&nbsp;&nbsp;
            <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {company}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" textColor="text">
            Rows:&nbsp;&nbsp;&nbsp;
            <SuiTypography variant="caption" fontWeight="medium">
              {email}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiTypography variant="caption" textColor="text">
          Dtype:&nbsp;&nbsp;&nbsp;
          <SuiTypography variant="caption" fontWeight="medium">
            {vat}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
