import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import { useState } from "react";
import { useAuth } from "../../auth-context/auth.context";
function Tables() {
  // const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  let { user } = useAuth();
  // function decrementNum() {
  //   setNum((prev) => prev - 1);
  // }
  const vtot = () => {
    const uploadData = new FormData();
    if (text == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else {
      try {
        uploadData.append("text", text);
        uploadData.append("user", user["username"]);
        fetch("http://45.127.6.59/api/users/dlaket2voice", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            setMes(data.status);
            // decrementNum();
          })
          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
      }
    }
    // decrementNum();
    return setMes(mes);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <SuiBox mt={5} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={12}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={text}
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                    type="text"
                    placeholder="Content"
                    multiline
                    rows={7}
                  />
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={9}></Grid>
              <Grid item xs={12} md={6} xl={3}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton variant="gradient" buttonColor="dark" onClick={() => vtot()} fullWidth>
                    voice to text
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
            <h6 style={{ color: "#0bd60b" }}>{mes}</h6>
            <h6 style={{ color: "#0bd60b" }}>{error}</h6>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Tables;
