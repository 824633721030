import PropTypes from "prop-types";
import axios from "axios";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Invoice({ date, id, price, download, pk, movdlake, noGutter }) {
  const delete_ = (val) => {
    var result = confirm("File " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`http://45.127.6.59/api/users/s3file/${val}`).then((res) => {
        console.log(res);
      });

      {
        window.location.reload();
      }
    }
  };
  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SuiBox lineHeight={1}>
        <SuiTypography display="block" variant="button" fontWeight="medium">
          {date}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {id}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" alignItems="left">
        <SuiTypography variant="button" fontWeight="regular" textColor="text">
          {price}
        </SuiTypography>

        <SuiBox
          display="flex"
          alignItems="center"
          lineHeight={0}
          ml={3}
          customClass="cursor-pointer"
        >
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            &nbsp;{download}
          </SuiTypography>
        </SuiBox>

        <SuiBox
          display="flex"
          alignItems="center"
          lineHeight={0}
          ml={3}
          customClass="cursor-pointer"
        >
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            &nbsp;{movdlake}
          </SuiTypography>
        </SuiBox>

        <SuiBox
          display="flex"
          alignItems="center"
          lineHeight={0}
          ml={3}
          customClass="cursor-pointer"
        >
          &nbsp;{" "}
          <td align="center">
            <Icon
              className="cursor-pointer"
              color="error"
              fontSize="large"
              onClick={() => delete_(pk)}
            >
              delete
            </Icon>
          </td>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  download: PropTypes.string.isRequired,
  pk: PropTypes.string.isRequired,
  movdlake: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
