import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function ProfileInfoCard({ title, description, title1, description1 }) {
  const labels = [];
  const values = [];

  const renderItems = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" textColor="text">
        &nbsp;{values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  const renderItems1 = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" textColor="text">
        &nbsp;{values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  return (
    <Card className="h-100">
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>{renderItems}</SuiBox>
      </SuiBox>

      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title1}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            {description1}
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>{renderItems1}</SuiBox>
      </SuiBox>
    </Card>
  );
}

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
};

export default ProfileInfoCard;
