import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Webcam from "react-webcam";
import SuiButton from "components/SuiButton";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactMediaRecorder } from "react-media-recorder";
import SuiTypography from "components/SuiTypography";
import { useAuth } from "auth-context/auth.context";
// const WebcamComponent = () => <Webcam />
// const videoConstraints = {
//   width: 490,
//   height: 500,
//   facingMode: "user",
// };

const Profile = () => {
  const [picture, setPicture] = useState("");
  const [folname, setFolname] = useState("");
  const webcamRef = React.useRef(null);
  const [datas3bucket, setS3Bucket] = useState([]);
  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  let { user } = useAuth();
  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/s3bucket`, {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setS3Bucket(res.data))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const save = () => {
    decrementNum();
    const uploadData = new FormData();
    if (folname == "") {
      setMes(undefined);
      return setError("Select A Folder");
    } else if (picture == "") {
      setMes(undefined);
      return setError("Capture An Image");
    } else {
      uploadData.append("folname", folname);
      uploadData.append("picture", picture);
      uploadData.append("user", user["username"]);
      fetch("http://45.127.6.59/api/users/s3filevideo", {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status == -1) {
            {
              setMes("");
            }
            {
              setError("Unknown Error");
            }
          }

          if (data.status == 1) {
            {
              setError("");
            }
            {
              setMes(" Successfully Uploaded");
            }
            decrementNum();
          }
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={7}>
              <div>
                {error && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                  </SuiBox>
                )}
                {mes && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                  </SuiBox>
                )}
              </div>

              <div>
                <ReactMediaRecorder
                  video
                  render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
                    <div>
                      <SuiTypography>{status}</SuiTypography>
                      {mediaBlobUrl && <video src={mediaBlobUrl} controls autoPlay loop />}
                      {!mediaBlobUrl && (
                        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                      )}
                      <div>
                        <div>
                          {mediaBlobUrl && (
                            <select
                              name="dtype"
                              id="d_type"
                              style={{
                                width: "50%",
                                height: 35,
                                color: "#525557",
                                backgroundColor: "#e4e9eb",
                                border: "3px solid #e4e9eb",
                              }}
                              onChange={(event) => {
                                setFolname(event.target.value), setPicture(mediaBlobUrl);
                              }}
                            >
                              <option value="">--SELECT--</option>
                              {datas3bucket.map((s3, index) => {
                                return (
                                  <option key={index} value={s3.Fname}>
                                    {s3.Fname}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                        <br></br>
                        {status == "stopped" && (
                          <a href="/capturevideo">
                            <SuiButton buttonColor="dark" width={100}>
                              Start Recording
                            </SuiButton>
                          </a>
                        )}
                        {status == "idle" && (
                          <SuiButton buttonColor="dark" onClick={startRecording} width={100}>
                            Start Recording
                          </SuiButton>
                        )}
                        {status == "recording" && (
                          <SuiButton buttonColor="dark" onClick={stopRecording} width={100}>
                            Stop Recording!
                          </SuiButton>
                        )}
                        &nbsp;&nbsp;
                        {mediaBlobUrl && (
                          <SuiButton
                            variant="gradient"
                            buttonColor="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              setPicture(mediaBlobUrl), save();
                            }}
                            width={100}
                          >
                            Save recorded
                          </SuiButton>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} xl={1}></Grid>
            <Grid item xs={12} md={6} xl={4}></Grid>
          </Grid>
        </SuiBox>
        <SuiBox>
          <SuiBox></SuiBox>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
};

export default Profile;
