import Grid from "@mui/material/Grid";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import Card from "@mui/material/Card";
import clsx from "clsx";
import S3folderInfoCard from "examples/Cards/InfoCards/S3folderInfoCard";
import styles from "layouts/tables/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiButton from "components/SuiButton";
import Icon from "@mui/material/Icon";
// import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { useState, useEffect } from "react";
import axios from "axios";
import homeDecor3 from "assets/images/imgcap.jpg";
import homeDecor2 from "assets/images/videocap.jpg";
import homeDecor1 from "assets/images/audiorec.jpg";
import { useAuth } from "../../auth-context/auth.context";

function Billing(outlined) {
  const classes = styles();
  const [Fname, setFName] = useState("");
  const [Description, setDescription] = useState("");
  const [datas3bucket, setS3Bucket] = useState([]);
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  const [num, setNum] = useState(0);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  let { user } = useAuth();

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/s3bucket`, {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setS3Bucket(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  const delete_ = (val) => {
    var result = confirm("Folder " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`http://45.127.6.59/api/users/s3bucket/${val}`).then((res) => {
        console.log(res);
      });

      {
        window.location.reload();
      }
    }
  };

  const newFolder = () => {
    const uploadData = new FormData();
    if (Fname == "") {
      setMes(undefined);
      return setError("You must Enter Folder Name");
    } else if (Description == "") {
      setMes(undefined);
      return setError("You must Enter Description");
    } else {
      try {
        uploadData.append("Fname", Fname);
        uploadData.append("user", user["username"]);
        uploadData.append("Description", Description);

        fetch(`http://45.127.6.59/api/users/s3bucket`, {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.status == 0) {
              {
                setMes("");
              }
              {
                setError(" Folder Already Exist");
              }
            }
            if (data.status == 1) {
              {
                setError("");
              }
              {
                setMes(" Successfully Added");
              }
              if (data.status == -1) {
                {
                  setMes("");
                }
                {
                  setError("Unknown Error");
                }
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                {datas3bucket.map((s3, index) => {
                  return (
                    <Grid item xs={12} md={6} xl={3} key={index}>
                      &nbsp;{" "}
                      <Icon
                        className="cursor-pointer"
                        color="error"
                        fontSize="medium"
                        onClick={() => delete_(s3.Fname)}
                      >
                        delete
                      </Icon>
                      <a href={"/#/s3folder/?folder=" + s3.Fname}>
                        <S3folderInfoCard
                          icon="folder"
                          title={s3.Fname}
                          description={s3.Description}
                        />
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={Fname}
                        onChange={(event) => {
                          setFName(event.target.value);
                        }}
                        type="text"
                        placeholder="Folder Name"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={Description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                        type="text"
                        placeholder="Folder Description"
                      />
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={() => newFolder()}
                        fullWidth
                      >
                        Create A Folder
                      </SuiButton>
                    </SuiBox>
                  </div>
                </SuiBox>
                {error && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                  </SuiBox>
                )}
                {mes && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                  </SuiBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <br></br>
          <br></br>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <a href="/#/captureimage">
                <img src={homeDecor3}></img>
              </a>
              IMAGE
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <a href="/#/capturevideo">
                <img src={homeDecor2}></img>
              </a>
              VIDEO
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <a href="/#/captureaudio">
                <img src={homeDecor1}></img>
              </a>
              VOICE
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <a href="/#/newtable">
                <PlaceholderCard title={{ variant: "h5", text: "New Table" }} outlined />
              </a>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox>
          <SuiBox></SuiBox>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Billing;
