/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO Material-UI components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO Material-UI example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card className="h-100">
      <SuiBox pt={3} px={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          AI Solutions
        </SuiTypography>
        <SuiBox mt={1} mb={2}>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            <SuiTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon className="font-bold text-success">arrow_upward</Icon>
            </SuiTypography>
            &nbsp;
            <SuiTypography variant="button" textColor="text" fontWeight="medium">
              Steps
            </SuiTypography>{" "}
            follow
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox p={2}>
        <TimelineItem color="success" icon="rockets" title="Deploy Model" dateTime="AI Solutions" />
        <TimelineItem color="error" icon="inventory_2" title="Create Model" dateTime="ML Model" />
        <TimelineItem
          color="info"
          icon="settings"
          title="Algorithm tuning"
          dateTime="Hyperparameters"
        />
        <TimelineItem
          color="warning"
          icon="laptop"
          title="Problem and Solutions"
          dateTime="Fix Requirments"
        />
        <TimelineItem
          color="dark"
          icon="engineering"
          title="Data engineering"
          dateTime="In build functions Mizu"
        />
        <TimelineItem
          color="primary"
          icon="upload"
          title="Upload Raw Data"
          dateTime="To data lake"
        />
      </SuiBox>
    </Card>
  );
}

export default OrdersOverview;
