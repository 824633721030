import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import borders from "assets/theme/base/borders";
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import { useEffect, useState } from "react";
import axios from "axios";
import SuiInput from "components/SuiInput";
import { useAuth } from "auth-context/auth.context";
function PaymentMethod() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const { borderWidth, borderColor } = borders;
  const [cname_automl, setCName_AutoMl] = useState("");
  const [send, setSend] = useState("");
  const [num, setNum] = useState(0);
  const [result, setResult] = useState("");
  const [result0, setResult0] = useState("");
  let { user } = useAuth();
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  const [colsdtype, setColsDtype] = useState([]);
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  const [dep, setDep] = useState("");
  // const [dep0, setDep0] = useState("");
  const [algm0, setAlgm0] = useState("");
  const [score0, setScore0] = useState("");
  const [param0, setParam0] = useState("");
  const [algm, setAlgm] = useState("");
  const [train_score, setErr] = useState("");
  const [test_score, setErrtest] = useState("");
  const [algm1, setAlgm1] = useState("");
  const [train_score1, setErr1] = useState("");
  const [test_score1, setErr1test] = useState("");
  const [algm2, setAlgm2] = useState("");
  const [train_score2, setErr2] = useState("");
  const [test_score2, setErr2test] = useState("");
  const [score, setScore] = useState("");
  const [score1, setScore1] = useState("");
  const [score2, setScore2] = useState("");
  const [param, setParam] = useState("");
  const [btext, setBtext] = useState("hypertune");
  const [btext1, setBtext1] = useState("hypertune");
  const [btext2, setBtext2] = useState("hypertune");
  const [modelname, setModelname] = useState("");

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/infodw/${project}`)
        .then((res) => setColsDtype(res.data.coldtype));
    }

    if (num < 1) {
      incrementNum();
    }
  });

  const newproject = (talgm, par, sc) => {
    const uploadData = new FormData();
    if (project == "") {
      setMes(undefined);
      return setError("Unknown Error");
    } else if (modelname == "") {
      setMes(undefined);
      return setError("Fill Model Name");
    } else {
      try {
        uploadData.append("user", user["username"]);
        uploadData.append("project", project);
        uploadData.append("dependent", dep);
        uploadData.append("algorithm", talgm);
        uploadData.append("parameter", par);
        uploadData.append("score", sc);
        uploadData.append("modelname", modelname);

        fetch("http://45.127.6.59/api/users/project", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 0) {
              setMes(undefined);
              return setError("Model Name Already Exist !!!");
            }
            if (data.status == 1) {
              window.location.reload();
            }
            if (data.status == -1) {
              setMes(undefined);
              return setError("Space Not A Name !!!");
            }
            if (data.status == -2) {
              setMes(undefined);
              return setError("Unknown Error !!!");
            }
            if (data.status == 10) {
              setMes(undefined);
              return setError("catched....");
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  const hypertuning = (str) => {
    setBtext("BUSY...");
    setBtext1("BUSY...");
    setBtext2("BUSY...");
    setResult0("");
    setAlgm0("");
    setScore0("");
    setParam0("");

    if (str == "LinearRegression") {
      console.log(str);
      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    } else if (str == "Ridge") {
      if (cname_automl == "") {
        setMes(undefined);
        return setError("You Must Select Dependent variable");
      }
      {
        axios.get(`http://45.127.6.59/api/users/ridgehyper/${send}`).then((res) => {
          setResult0(res.data.status),
            // setDep0(res.data.dep),
            setAlgm0(res.data.algm),
            setParam0(res.data.param),
            setScore0(res.data.score),
            setError(""),
            setMes("");
          setBtext("Hypertune"), setBtext1("Hypertune"), setBtext2("Hypertune");
        });
        decrementNum();
      }

      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    } else if (str == "RandomForestRegressor") {
      if (cname_automl == "") {
        setMes(undefined);
        return setError("You Must Select Dependent variable");
      }

      {
        axios.get(`http://45.127.6.59/api/users/randomreghyper/${send}`).then((res) => {
          setResult0(res.data.status),
            // setDep0(res.data.dep),
            setAlgm0(res.data.algm),
            setParam0(res.data.param),
            setScore0(res.data.score),
            setError(""),
            setMes("");
          setBtext("Hypertune"), setBtext1("Hypertune"), setBtext2("Hypertune");
        });
        decrementNum();
      }
      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    } else if (str == "GaussianProcessRegressor") {
      if (cname_automl == "") {
        setMes(undefined);
        return setError("You Must Select Dependent variable");
      }

      {
        axios.get(`http://45.127.6.59/api/users/guassianreghyper/${send}`).then((res) => {
          setResult0(res.data.status),
            // setDep0(res.data.dep),
            setAlgm0(res.data.algm),
            setParam0(res.data.param),
            setScore0(res.data.score),
            setError(""),
            setMes("");
          setBtext("Hypertune"), setBtext1("Hypertune"), setBtext2("Hypertune");
        });
        decrementNum();
      }
      console.log(str);
      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    } else if (str == "DecisionTreeRegressor") {
      if (cname_automl == "") {
        setMes(undefined);
        return setError("You Must Select Dependent variable");
      }

      {
        axios.get(`http://45.127.6.59/api/users/decisiontreereg/${send}`).then((res) => {
          setResult0(res.data.status),
            // setDep0(res.data.dep),
            setAlgm0(res.data.algm),
            setParam0(res.data.param),
            setScore0(res.data.score),
            setError(""),
            setMes("");
          setBtext("Hypertune"), setBtext1("Hypertune"), setBtext2("Hypertune");
        });
        decrementNum();
      }
      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    } else {
      console.log(str);
      setBtext("HYPERTUNE");
      setBtext1("HYPERTUNE");
      setBtext2("HYPERTUNE");
    }
  };

  const automltuning = () => {
    if (cname_automl == "") {
      setMes(undefined);
      return setError("You Must Select Dependent variable");
    }
    {
      axios.get(`http://45.127.6.59/api/users/automlregression/${send}`).then((res) => {
        setResult(res.data.status),
          setDep(res.data.dep),
          setAlgm(res.data.algm),
          setAlgm1(res.data.algm1),
          setAlgm2(res.data.algm2),
          setParam(res.data.param),
          setScore(res.data.score),
          setScore1(res.data.score1),
          setScore2(res.data.score2),
          setErr(res.data.train_score),
          setErr1(res.data.train_score1),
          setErr2(res.data.train_score2),
          setErrtest(res.data.test_score),
          setErr1test(res.data.test_score1),
          setErr2test(res.data.test_score2),
          setError(""),
          setMes("");
      });

      decrementNum();
    }
  };

  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          AutoML - Without Hyper Parameter Tuning
        </SuiTypography>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}

        <SuiButton variant="gradient" buttonColor="dark" onClick={() => automltuning()}>
          <Icon className="font-bold">code</Icon>&nbsp;tune
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <SuiBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2} />
              <select
                name="dtcol"
                id="dtcol"
                style={{
                  width: "50%",
                  height: 35,
                  color: "#525557",
                  backgroundColor: "#e4e9eb",
                  border: "3px solid #e4e9eb",
                }}
                onChange={(event) => {
                  setCName_AutoMl(event.target.value),
                    setSend(String(project) + "=" + String(event.target.value));
                }}
              >
                <option value="">--SELECT--</option>
                {colsdtype.map(function (objt, index) {
                  return (
                    <option value={objt.col} key={index}>
                      {objt.col}
                    </option>
                  );
                })}
              </select>
            </SuiBox>
            <p></p>

            {cname_automl && (
              <SuiTypography variant="caption" textColor="secondary">
                Dependent Variable : {cname_automl}
              </SuiTypography>
            )}
            <span>&nbsp;&nbsp;&nbsp;</span>
            <p></p>

            {result && (
              <SuiInput
                value={modelname}
                onChange={(event) => {
                  setModelname(event.target.value);
                }}
                type="text"
                placeholder="Model Name"
              />
            )}
            {result && (
              <p>
                <br></br>
              </p>
            )}
            {result0 && (
              <SuiTypography variant="caption" textColor="secondary">
                Tuned Algorithm : {algm0}
              </SuiTypography>
            )}
            <p></p>
            {result0 && (
              <SuiTypography variant="caption" textColor="secondary">
                Tuned Score : {score0}
              </SuiTypography>
            )}
            <p></p>
            {result0 && (
              <SuiTypography variant="caption" textColor="secondary">
                Params : {param0}
              </SuiTypography>
            )}
            <p></p>
            {result && (
              <p>
                <br></br>
              </p>
            )}
            {result0 && (
              <SuiButton
                variant="gradient"
                buttonColor="success"
                onClick={() => newproject(algm0, param0, score0)}
              >
                <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;save project
              </SuiButton>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <SuiBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              {!result && (
                <SuiBox component="img" src={visaLogo} alt="master card" width="10%" mr={2} />
              )}
              <SuiTypography variant="h6" fontWeight="medium">
                {result && <SuiTypography variant="caption">SUCCESSFULLY TUNED</SuiTypography>}
                <p></p>
                {result && <SuiTypography variant="caption">Dependent : {dep}</SuiTypography>}
                <p></p>
                {result && <SuiTypography variant="caption">Parameter : {param}</SuiTypography>}
                <p></p>
                {result && (
                  <p>
                    <br></br>
                  </p>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Top 1 Algorithm : {algm}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Score 1 : {score}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Train Score : {train_score}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Test Score : {test_score}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => hypertuning(algm)}
                  >
                    <Icon className="font-bold">code</Icon>&nbsp; {btext}
                  </SuiButton>
                )}
                {result && <span>&nbsp;&nbsp;&nbsp;</span>}
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="success"
                    onClick={() => newproject(algm, param, score)}
                  >
                    <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;save project
                  </SuiButton>
                )}
                <p></p>
                {result && (
                  <p>
                    <br></br>
                  </p>
                )}
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Top 2 Algorithm : {algm1}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Score 2 : {score1}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Train Score : {train_score1}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Test Score : {test_score1}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => hypertuning(algm1)}
                  >
                    <Icon className="font-bold">code</Icon>&nbsp;{btext1}
                  </SuiButton>
                )}
                {result && <span>&nbsp;&nbsp;&nbsp;</span>}
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="success"
                    onClick={() => newproject(algm1, param, score1)}
                  >
                    <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;save project
                  </SuiButton>
                )}
                <p></p>
                {result && (
                  <p>
                    <br></br>
                  </p>
                )}
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Top 3 Algorithm : {algm2}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Score 3 : {score2}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Train Score : {train_score2}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiTypography variant="caption" textColor="secondary">
                    Test Score : {test_score2}
                  </SuiTypography>
                )}
                <p></p>
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => hypertuning(algm2)}
                  >
                    <Icon className="font-bold">code</Icon>&nbsp; {btext2}
                  </SuiButton>
                )}
                {result && <span>&nbsp;&nbsp;&nbsp;</span>}
                {result && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="success"
                    onClick={() => newproject(algm2, param, score2)}
                  >
                    <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;save project
                  </SuiButton>
                )}
                <p></p>
              </SuiTypography>

              <SuiBox ml="auto" lineHeight={0}></SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PaymentMethod;
