import marie from "assets/images/v2t.png";
import marie1 from "assets/images/t2v.png";
// import plot from "assets/images/tex.png";
import ml from "assets/images/ml.jpg";
import dm from "assets/images/domain.png";
import merge from "assets/images/merge.png";
import imtotxt from "assets/images/imtotxt.png";
// import ivana from "assets/images/small-logos/datawarehouse2.png";
export default [
  {
    image: dm,
    name: "Domain Specific",
    description: "Specific Use Case",
    action: {
      type: "internal",
      route: `/DomainSpecificL`,
      color: "info",
      label: "click",
    },
  },
  {
    image: ml,
    name: "Machine Learning",
    description: "Data Engineering",
    action: {
      type: "internal",
      route: `/MachineL`,
      color: "info",
      label: "click",
    },
  },
  {
    image: merge,
    name: "Merge Dataframe",
    description: "Identical dataframe can merge here",
    action: {
      type: "internal",
      route: `/mergedf`,
      color: "info",
      label: "click",
    },
  },
  {
    image: marie,
    name: "Speech to Text",
    description: "Convert Audio to Text",
    action: {
      type: "internal",
      route: `/s2t`,
      color: "info",
      label: "click",
    },
  },

  {
    image: marie1,
    name: "Text to Speech",
    description: "Convert Text to Audio",
    action: {
      type: "internal",
      route: `/t2s`,
      color: "info",
      label: "click",
    },
  },
  {
    image: imtotxt,
    name: "Extract Text From Image",
    description: "OCR",
    action: {
      type: "internal",
      route: `/ocr`,
      color: "info",
      label: "click",
    },
  },
];
