import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import kal from "assets/images/deng.png";
import burceMars from "assets/images/dengg.png";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProjectInfoCard";
import Header from "layouts/profile/components/Header";
// import PlatformSettings from "layouts/profile/components/ColumnsSettings";
import SuiButton from "components/SuiButton";
import { useEffect, useState } from "react";
import axios from "axios";
import Icon from "@mui/material/Icon";
import styles from "layouts/tables/styles";
import SuiAvatar from "components/SuiAvatar";
// const params = new URLSearchParams(document.location.search);
function Overview() {
  const classes = styles();
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [pname, setPname] = useState("");
  const [fname, setFname] = useState("");
  const [fsize, setSize] = useState("");
  const [dateupl, setDateupl] = useState("");
  const [extensions, setExtensions] = useState("");
  const [statusdl, setStatusdl] = useState("");
  const [n_cols, setnCols] = useState("");
  const [rows, setRows] = useState("");
  const [total_null, setTotal_null] = useState("");
  const [categorical, setCategorical] = useState("");
  const [nullcols, setNullcols] = useState([]);
  // const [dfj,setDfj]=useState([]);
  const [dfjf, setDfjf] = useState([]);
  const [insp, setInsp] = useState([]);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios.get(`http://45.127.6.59/api/users/selectdlake/${project}`).then(
        (res) => (
          setPname(res.data.pname),
          setFname(res.data.fname),
          setSize(res.data.fsize + " GB"),
          setDateupl(res.data.dateupl),
          setExtensions(res.data.extensions),
          setStatusdl(res.data.statusdl),
          setnCols(res.data.n_cols),
          setRows(res.data.rows),
          setTotal_null(res.data.total_null),
          setCategorical(res.data.categorical),
          setNullcols(res.data.nullcols),
          setInsp(res.data.inspection),
          // setDfj(res.data.dfj),
          setDfjf(res.data.dfjf)
        )
      );
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title={pname}
              description={fname}
              info={{
                size: fsize,
                file: extensions,
                columns: n_cols,
                rows: rows,
                nulls: total_null,
                categoricals: categorical,
                date: dateupl,
                status: statusdl,
              }}
              social={[]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <iframe
              className="responsive-iframe"
              srcDoc={insp}
              width="100%"
              height="250pt"
              style={{
                background: "#f2f5fa",
              }}
            ></iframe>
          </Grid>
          <Grid item xs={12} xl={4}>
            <SuiAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              customClass="shadow-sm"
            />
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              Data Engineering Instructions
            </SuiTypography>
            <tbody>
              {nullcols.map(function (objt, index) {
                return (
                  <tr key={index}>
                    <td>
                      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                        <SuiBox mr={2}>
                          <SuiAvatar src={kal} alt={"name"} size="sm" variant="rounded" />
                        </SuiBox>
                      </SuiBox>
                    </td>
                    <td>
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                          <td>
                            <SuiBox display="flex" flexDirection="column">
                              {objt}
                            </SuiBox>
                          </td>
                        </SuiTypography>
                      </SuiBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <SuiButton variant="gradient" buttonColor="dark">
              <a style={{ color: "#fff" }} href={"/#/cleanfunction/?project=" + pname}>
                <Icon className="font-bold">settings</Icon>&nbsp;&nbsp;&nbsp;DATA CLEANING
              </a>
            </SuiButton>
            {/* <p><br></br></p>
          <PlatformSettings /> */}
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox pt={2} px={2}>
              <SuiBox mb={0.5}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {pname}
                </SuiTypography>
              </SuiBox>
            </SuiBox>

            <SuiBox pt={2} px={2}>
              <SuiBox mb={0.5}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Data
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <iframe
                className="responsive-iframe"
                srcDoc={dfjf}
                width="100%"
                height="300pt"
                style={{
                  backgroundImage:
                    "url(" +
                    "https://img.freepik.com/free-vector/watercolor-with-blue-detailed-background_1409-1170.jpg?w=1200&t=st=1707896022~exp=1707896622~hmac=fb06c2d711439b3a873b382324c9a014cf2b462ebf6929e3e2dd8ff59ac0b89c" +
                    ")",
                  background: "#2f5fa",
                }}
              ></iframe>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
