import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import image from "assets/images/small-logos/setting.png";
import breakpoints from "assets/theme/base/breakpoints";
import styles from "layouts/profile/components/Header/styles";
import burceMars from "assets/images/deng.png";
const params = new URLSearchParams(document.location.search);
function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const classes = styles();
  const project = params.get("project");
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <SuiBox position="relative">
      <DashboardNavbar absolute light />
      <SuiBox customClass={classes.profileHeader_background} />
      <Card className={classes.profileHeader_profile}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SuiAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              customClass="shadow-sm"
            />
          </Grid>
          <Grid item>
            <SuiBox height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {project}
              </SuiTypography>
              <SuiTypography variant="button" textColor="text" fontWeight="small">
                project
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="ml-auto">
            <AppBar position="static">
              <a href="/#/infotxt">
                <SuiAvatar src={image} alt={"name"} size="sm" variant="rounded" />
              </a>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SuiBox>
  );
}

export default Header;
