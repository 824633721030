import Icon from "@mui/material/Icon";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Office from "examples/Icons/Basket";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../auth-context/auth.context";
import styles from "layouts/tables/styles";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import DatePicker from "react-datepicker";
function Dashboard() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const classes = styles();
  let { user } = useAuth();
  const [html_table, setHtml_table] = useState([]);
  const [datalen, setDatalen] = useState("");
  const [date, setDate] = useState("");
  const [num, setNum] = useState(0);
  // const [data, setData] = useState([]);
  const [forecast_results, setForcast] = useState("");
  const [dataset, setDataset] = useState([]);
  // const [monthly_counts, setMonthly_counts] = useState("");

  const [months, setMonth] = useState([]);
  const [lipre, setLipre] = useState([]);
  const [lipre_, setLipre_] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const { chart } = {
    chart: {
      labels: lipre,
      datasets: { label: "Counts", data: lipre_ },
    },
  };

  function incrementNum() {
    setNum((prev) => prev + 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/rest1dash/${project}`, {
          params: {
            username: user["username"],
            startDate: startDate,
          },
        })
        .then(
          (res) => (
            setHtml_table(res.data.html_table),
            setDatalen(res.data.datalen),
            setDate(res.data.date),
            // setData(res.data.data),
            setForcast(res.data.forecast_results),
            setDataset(res.data.dataset),
            // setMonthly_counts(res.data.monthly_counts),
            setMonth(res.data.months),
            setLipre(res.data.lipre),
            setLipre_(res.data.lipre_)
          )
        );
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={2}>
            <MiniStatisticsCard
              title={{ text: "Total : " + datalen }}
              percentage={{ color: "primary", text: date }}
              icon={{ color: "info", component: <Office size="0px" /> }}
            />
          </Grid>
          <Grid container item xs={12} sm={6} xl={8} spacing={3}>
            {Object.entries(forecast_results).map(([food, value]) => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={food}>
                <MiniStatisticsCard
                  title={{ text: food }}
                  count={value}
                  percentage={{ color: "success", text: "" }}
                  icon={{ color: "info", component: <Office size="20px" /> }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} xl={2}>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date), setNum((prev) => prev - 1);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy"
              timeCaption="Time"
              utcOffset={8 * 60}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={7}>
            <GradientLineChart
              title="12 months"
              description={
                <SuiBox display="flex" alignItems="center">
                  <SuiBox color="success" mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon className="font-bold">arrow_upward</Icon>
                  </SuiBox>
                  <SuiTypography variant="button" textColor="text" fontWeight="medium">
                    Count
                    <SuiTypography
                      variant="button"
                      textColor="text"
                      fontWeight="regular"
                    ></SuiTypography>
                  </SuiTypography>
                </SuiBox>
              }
              height="20.25rem"
              chart={{
                labels: months,
                datasets: dataset,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <ReportsBarChart
              title=" SALES PREDICTION"
              description={
                <>
                  (<strong>Date</strong>) :{date}
                </>
              }
              chart={chart}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox customClass={classes.tables_table}>
        <iframe
          className="responsive-iframe"
          srcDoc={html_table}
          width="100%"
          height="300pt"
          style={{
            backgroundImage:
              "url(" +
              "https://img.freepik.com/free-vector/watercolor-with-blue-detailed-background_1409-1170.jpg?w=1200&t=st=1707896022~exp=1707896622~hmac=fb06c2d711439b3a873b382324c9a014cf2b462ebf6929e3e2dd8ff59ac0b89c" +
              ")",
            background: "#2f5fa",
          }}
        ></iframe>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
