import Card from "@mui/material/Card";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import Header from "layouts/profile/components/Header";

function Infotxt(outlined) {
  const classes = styles();
  const [proinfo, setProinfo] = useState("");
  const [proinfos, setProinfos] = useState("");
  const [proinfoh, setProinfoh] = useState("");
  const [binfoh, setBinfoh] = useState("");
  const [error, setError] = useState(undefined);

  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("http://45.127.6.59/api/users/infotxt")
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const newdf = () => {
    const uploadData = new FormData();
    if (proinfo == "") {
      setMes(undefined);
      return setError("Enter Project Info");
    } else if (proinfoh == "") {
      setMes(undefined);
      return setError("Enter Business Info Heading");
    } else if (binfoh == "") {
      setMes(undefined);
      return setError("Enter Business Info Heading");
    } else if (proinfos == "") {
      setMes(undefined);
      return setError("Enter Business Info Description");
    } else {
      try {
        uploadData.append("proinfo", proinfo);

        uploadData.append("proinfoh", proinfoh);
        uploadData.append("proinfos", proinfos);

        uploadData.append("binfoh", binfoh);
        fetch("http://45.127.6.59/api/users/infotxt", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.status == 0) {
              {
                setMes("");
              }
            }

            if (data.status == 1) {
              {
                setError("");
              }
              decrementNum();
            }

            if (data.status == -1) {
              {
                setMes("");
              }
              {
                setError("Unknown Error");
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={2} mb={3}>
        <Card
          raised
          className={clsx(classes.placeholderCard, {
            [classes.placeholderCard_outlined]: outlined,
          })}
        >
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            height="100%"
            p={3}
          >
            <div>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfoh}
                  onChange={(event) => {
                    setProinfoh(event.target.value);
                  }}
                  type="text"
                  placeholder="Office Location"
                />
              </SuiBox>

              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfos}
                  onChange={(event) => {
                    setProinfos(event.target.value);
                  }}
                  type="text"
                  placeholder="Car Brand"
                />
              </SuiBox>

              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfo}
                  onChange={(event) => {
                    setProinfo(event.target.value);
                  }}
                  type="text"
                  placeholder="Number Plate L"
                />
              </SuiBox>

              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={binfoh}
                  onChange={(event) => {
                    setBinfoh(event.target.value);
                  }}
                  type="text"
                  placeholder="Camera Brand "
                />
              </SuiBox>

              <SuiBox mb={1} ml={0.5}>
                <SuiButton variant="gradient" buttonColor="dark" onClick={() => newdf()} fullWidth>
                  ADD CAR
                </SuiButton>
              </SuiBox>
            </div>
            {error && (
              <SuiBox mt={2} mb={2} textAlign="center">
                <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
              </SuiBox>
            )}
            {mes && (
              <SuiBox mt={2} mb={2} textAlign="center">
                <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
              </SuiBox>
            )}
          </SuiBox>
        </Card>
        <Card></Card>
        <Footer />
      </SuiBox>
    </DashboardLayout>
  );
}

export default Infotxt;
