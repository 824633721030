import kal from "assets/images/regression.jpg";
import marie from "assets/images/classification.jpg";
import ivana from "assets/images/clusteringjpg.jpg";
import plot from "assets/images/plot.jpg";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProjectInfoCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/ColumnsSettings";
// const params = new URLSearchParams(document.location.search);
import { useEffect, useState } from "react";
import axios from "axios";
import styles from "layouts/tables/styles";
// import profilesListData from "layouts/profile/data/profilesListData";
import ProfilesList from "examples/ProfilesList";
function Overview() {
  const classes = styles();
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [pname, setPname] = useState("");
  const [fname, setFname] = useState("");
  const [fsize, setSize] = useState("");
  const [dateupl, setDateupl] = useState("");
  const [extensions, setExtensions] = useState("");
  const [statusdl, setStatusdl] = useState("");
  const [n_cols, setnCols] = useState("");
  const [rows, setRows] = useState("");
  const [total_null, setTotal_null] = useState("");
  const [categorical, setCategorical] = useState("");
  const [dfj, setDfj] = useState([]);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/selectdatawarehouse/${project}`)
        .then(
          (res) => (
            setPname(res.data.pname),
            setFname(res.data.fname),
            setSize(res.data.fsize + " GB"),
            setDateupl(res.data.dateupl),
            setExtensions(res.data.extensions),
            setStatusdl(res.data.statusdl),
            setnCols(res.data.n_cols),
            setRows(res.data.rows),
            setTotal_null(res.data.total_null),
            setCategorical(res.data.categorical),
            setDfj(res.data.dfj)
          )
        );
    }
    if (num < 1) {
      incrementNum();
    }
  });
  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title={pname}
              description={fname}
              info={{
                size: fsize,
                file: extensions,
                columns: n_cols,
                rows: rows,
                nulls: total_null,
                categoricals: categorical,
                date: dateupl,
                status: statusdl,
              }}
              social={[]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ProfilesList
              title="Machine Learning"
              profiles={[
                {
                  image: kal,
                  name: "REGRESSION",
                  description: "Regression Algorithms",
                  action: {
                    type: "internal",
                    route: `/algmtuning_reg/?project=${project}`,
                    color: "info",
                    label: "tuning",
                  },
                },
                {
                  image: marie,
                  name: "CLASSIFICATION",
                  description: "Classification Algorithms",
                  action: {
                    type: "internal",
                    route: `/algmtuning/?project=${project}`,
                    color: "info",
                    label: "tuning",
                  },
                },
                {
                  image: ivana,
                  name: "CLUSTERING",
                  description: "Clustering Algorithms",
                  action: {
                    type: "internal",
                    route: `/clustering/?project=${project}`,
                    color: "info",
                    label: "tuning",
                  },
                },
                {
                  image: plot,
                  name: "PLOTING",
                  description: "Data Ploting",
                  action: {
                    type: "internal",
                    route: `/dataploting/?project=${project}`,
                    color: "info",
                    label: "PLOTING",
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox pt={2} px={2}>
              <SuiBox mb={0.5}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {pname}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1}>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Data Frame
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <iframe
                className="responsive-iframe"
                srcDoc={dfj}
                width="100%"
                height="300pt"
                style={{
                  background: "#f2f5fa",
                  backgroundImage:
                    "url(" +
                    "https://img.freepik.com/free-vector/watercolor-with-blue-detailed-background_1409-1170.jpg?w=1200&t=st=1707896022~exp=1707896622~hmac=fb06c2d711439b3a873b382324c9a014cf2b462ebf6929e3e2dd8ff59ac0b89c" +
                    ")",
                }}
              ></iframe>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Overview;
