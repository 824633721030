import SuiBox from "components/SuiBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import SuiTypography from "components/SuiTypography";
import Button from "@material-ui/core/Button";
import Icon from "@mui/material/Icon";
import MasterCard from "examples/Cards/MasterCard";
import Header from "layouts/profile/components/Header";
const currentUrl = window.location.href;

function Billing(outlined) {
  const classes = styles();
  // const [ pname, setpName ] = useState("");
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  const project = currentUrl.split("?")[1].split("=")[1];
  const [dic, setDic] = useState([]);
  const [columns, setColumns] = useState([]);
  const [num, setNum] = useState(0);
  const [prediction, setPrediction] = useState();
  const [dependent, setDependent] = useState("");
  const [fileinput, setFileinput] = useState("");
  const [dfj, setDfj] = useState("");

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/app/${project}`)
        .then(
          (res) => (
            setDic(res.data.columns), setDependent(res.data.dependent), setColumns(res.data.columns)
          )
        )
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const [formFields, setFormFields] = useState([dic]);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    const uploadData = new FormData();
    for (let i = 0; i < columns.length; i++) {
      uploadData.append(i, formFields[0][columns[i]]);
    }
    uploadData.append("pname", project);
    fetch("http://45.127.6.59/api/users/predict", {
      method: "POST",
      body: uploadData,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("------->>>", res.prdiction);
        if (res.status == 1) {
          {
            setPrediction(res.prdiction), setDependent(res.dependant);
          }
        }
      });
  };

  const modelpredict = () => {
    const uploadData = new FormData();
    if (fileinput == "") {
      setMes(undefined);
      return setError("You must Upload A File");
    } else {
      try {
        uploadData.append("fileinput", fileinput, fileinput.name);
        uploadData.append("pname", project);
        fetch("http://45.127.6.59/api/users/predictfile", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 1) {
              {
                console.log("data");
              }
              {
                setDfj(data.dfj);
              }
              {
                setError("");
              }
            }
            {
              setMes("Predicted");
            }
            if (data.status == -1) {
              {
                setError("Wrong Inputs, Check Entries");
              }
              {
                setMes("");
              }
              console.log(dfj);
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <Header />
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}></Grid>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiTypography variant="caption" textColor="secondary">
                      Project Name :
                    </SuiTypography>
                    <SuiTypography variant="caption" textColor="dark">
                      <span>&nbsp;&nbsp;&nbsp;</span> {project}
                    </SuiTypography>
                    <p></p>
                    <SuiTypography variant="caption" textColor="secondary">
                      Predict By Enter Values
                    </SuiTypography>
                    <form onSubmit={submit}>
                      {formFields.map((form, index) => {
                        return (
                          <div key={index}>
                            {columns.map((item, ind) => (
                              <SuiBox mb={1} ml={0.5} key={ind}>
                                <SuiInput
                                  name={item}
                                  placeholder={item}
                                  onChange={(event) => handleFormChange(event, index)}
                                  value={form.name}
                                />
                              </SuiBox>
                            ))}
                          </div>
                        );
                      })}

                      <SuiBox mb={1} ml={0.5}>
                        <SuiButton variant="gradient" buttonColor="dark" onClick={submit} fullWidth>
                          predict
                        </SuiButton>
                      </SuiBox>
                      <SuiBox mb={1} ml={0.5}>
                        <SuiTypography variant="caption" textColor="secondary">
                          {dependent}
                        </SuiTypography>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <SuiTypography variant="caption" textColor="success">
                          {prediction}
                        </SuiTypography>
                      </SuiBox>
                    </form>
                  </div>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item xs={12} xl={12}>
                <MasterCard number={"ML APP"} holder={project} expires={dependent} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiBox mb={1} ml={0.5}>
                      <input
                        id="file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(evt) => setFileinput(evt.target.files[0])}
                      />
                      <label htmlFor="file">
                        <Button variant="contained" color="primary" component="span" fullWidth>
                          <Icon className="font-bold">add</Icon>
                          &nbsp; add file
                        </Button>
                      </label>
                      <SuiBox mt={2} mb={2} textAlign="center">
                        <h6 style={{ color: "grey", textAlign: "center" }}>{fileinput.name}</h6>
                      </SuiBox>
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={() => modelpredict()}
                        fullWidth
                      >
                        upload
                      </SuiButton>
                    </SuiBox>
                  </div>
                  {error && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                    </SuiBox>
                  )}
                  {mes && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                    </SuiBox>
                  )}
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <Grid item xs={12} md={11.98}>
        <SuiBox customClass={classes.tables_table}>
          {dfj && (
            <iframe
              className="responsive-iframe"
              srcDoc={dfj}
              width="100%"
              height="450pt"
              style={{ background: "#f2f5fa" }}
            ></iframe>
          )}
        </SuiBox>
      </Grid>
    </DashboardLayout>
  );
}

export default Billing;
