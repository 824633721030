import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PaymentMethod from "layouts/algmtuning/components/PaymentMethod";
// import SKAutoML from "layouts/algmtuning/components/SKAutoML";
// import RandomforestAutoml from "layouts/algmtuning/components/RandomforestAutoml";
// import LogisticTuning from "layouts/algmtuning/components/LogisticTuning";
import Invoices from "layouts/algmtuning/components/Invoices";

function Billing() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PaymentMethod />
                </Grid>
                {/* <Grid item xs={12}>
                  <RandomforestAutoml />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}></SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Billing;
