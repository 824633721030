import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import axios from "axios";
function PlatformSettings() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [fname, setFname] = useState();
  const [cols, setCols] = useState([]);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/selectdatawarehouse/${project}`)
        .then((res) => (setFname(res.data.fname), setCols(res.data.cols), console.log(res.data)));
    }
    if (num < 1) {
      incrementNum();
    }
  });
  return (
    <Card>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {fname}
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <SuiTypography
          variant="caption"
          fontWeight="bold"
          textColor="text"
          textTransform="uppercase"
        >
          columns
        </SuiTypography>
        <SuiBox display="flex" py={1} mb={0.25}>
          <SuiBox width="80%" ml={2}>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              {cols.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default PlatformSettings;
