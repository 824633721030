import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import styles from "examples/LayoutContainers/DashboardLayout/styles";
import { useSoftUIController } from "context";
// import axios from 'axios';
function LayoutContainer({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const classes = styles({ miniSidenav, direction });
  // const [user, setUser] = useState("");
  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "dashboard" });
    // {axios.get("http://127.0.0.1:5000/api/users/usernam")
    // .then(res => (
    // setUser(res.data["user"])

    //   ))}
  }, [pathname]);

  return <SuiBox customClass={classes.layoutContainer}>{children}</SuiBox>;
}

// Typechecking props for the LayoutContainer
LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
