import { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import styles from "layouts/dashboard/components/Projects/styles";
import Table from "examples/Table";
import authorsTableData from "layouts/tables/data/authorsTableData";
import SuiAvatar from "components/SuiAvatar";
import image from "assets/images/small-logos/project.png";
import { useAuth } from "../../../../auth-context/auth.context";
function Projects() {
  const [num, setNum] = useState(0);
  const { columns, rows } = authorsTableData;
  const [menu, setMenu] = useState(null);
  const [datalake, setDatalake] = useState([]);
  let { user } = useAuth();
  const classes = styles();
  function incrementNum() {
    setNum((prev) => prev + 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("http://45.127.6.59/api/users/dashproject/all", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setDatalake(res.data.project))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiBox>
          <SuiBox display="flex" alignItems="center" lineHeight={0}>
            <Icon className="font-bold text-info">done</Icon>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              &nbsp;<strong>{datalake.length} Works</strong> On Board
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox color="text" px={2}>
          <Icon className="cursor-pointer font-bold" fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </SuiBox>
        {renderMenu}
      </SuiBox>
      <Card>
        <SuiBox customClass={classes.tables_table}>
          <Table columns={columns} rows={rows} />
        </SuiBox>

        <SuiBox customClass={classes.tables_table}>
          <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
            <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
              <table className="MuiTable-root css-w793m5-MuiTable-root">
                <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                  <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      PROJECTS
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      STATUS
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      % COMPLETED
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {datalake.map(function (objt, index) {
                    return (
                      <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root" key={index}>
                        <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                          <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                            <SuiBox mr={2}>
                              <SuiAvatar src={image} alt={"name"} size="sm" variant="rounded" />
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column">
                              <SuiTypography variant="button" fontWeight="medium">
                                {objt.col}
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </td>
                        <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                          <SuiBox display="flex" flexDirection="column">
                            <SuiTypography
                              variant="caption"
                              fontWeight="medium"
                              textColor={objt.color}
                            >
                              {objt.status}
                            </SuiTypography>
                          </SuiBox>
                        </td>
                        <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                          <SuiBox display="flex" flexDirection="column">
                            <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                              {objt.perc}
                            </SuiTypography>
                          </SuiBox>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </SuiBox>
      </Card>
    </Card>
  );
}

export default Projects;
