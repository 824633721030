// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Icon from "@mui/material/Icon";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
import SuiInput from "components/SuiInput";

import image from "assets/images/team-pl.png";
import sub from "assets/images/sub.jpg";
import userimage from "assets/images/user.jpg";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../auth-context/auth.context";

function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;

  const [error, setError] = useState(undefined);

  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  const [select, setSelect] = useState("");
  const [selectpuser, setSelectPuser] = useState("");
  const [selectplan, setSelectPlan] = useState("");

  const [datalist, setDatalist] = useState([]);
  const [datchild, setDatachild] = useState([]);
  const [planlist, setPlanlist] = useState([]);
  const [newplanlist, setNewPlanlist] = useState([]);
  let { user } = useAuth();
  const [planname, setPlanName] = useState("");
  const [spec, setSpec] = useState("");
  const [price, setPrice] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if ((num == 0) & (user["username"] == "ramadan")) {
      axios
        .get("http://45.127.6.59/api/users/userlist", {
          params: { username: user["username"] },
        })
        .then((res) => setDatalist(res.data))
        .catch((err) => console.log(err));

      axios
        .get("http://45.127.6.59/api/users/addchild", {
          params: { username: user["username"] },
        })
        .then((res) => setDatachild(res.data))
        .catch((err) => console.log(err));

      axios
        .get("http://45.127.6.59/api/users/plan", {
          params: { username: user["username"] },
        })
        .then((res) => setPlanlist(res.data))
        .catch((err) => console.log(err));

      axios
        .get("http://45.127.6.59/api/users/newplan", {
          params: { username: user["username"] },
        })
        .then((res) => setNewPlanlist(res.data))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const addchild = () => {
    const uploadData = new FormData();
    if (select == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else {
      try {
        uploadData.append("child", select);
        uploadData.append("parent", user["username"]);
        fetch("http://45.127.6.59/api/users/addchild", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  const addplan = () => {
    const uploadData = new FormData();
    if (selectplan == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else if (selectpuser == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else {
      try {
        uploadData.append("plan", selectplan);
        uploadData.append("parent", selectpuser);
        fetch("http://45.127.6.59/api/users/plan", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  const addnewplan = () => {
    const uploadData = new FormData();
    if (planname == "") {
      setMes(undefined);
      return setError("You must Enter Plan");
    } else if (spec == "") {
      setMes(undefined);
      return setError("You must Enter Spec");
    } else if (price == "") {
      setMes(undefined);
      return setError("You must Enter price");
    } else {
      try {
        uploadData.append("planname", planname);
        uploadData.append("spec", spec);
        uploadData.append("price", price);

        fetch("http://45.127.6.59/api/users/newplan", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  const deletel_row_df = (val) => {
    var result = confirm("Child " + val + " Confirm To Delete?");
    if (result) {
      axios.delete(`http://45.127.6.59/api/users/addchild/${val}`, {
        params: {
          username: user["username"],
        },
      });

      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  const deletel_row_plan = (val) => {
    var result = confirm("Plan of  " + val + " Confirm To Delete?");
    if (result) {
      axios.delete(`http://45.127.6.59/api/users/plan/${val}`, {
        params: {
          username: user["username"],
        },
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  const deletel_row_nplan = (val) => {
    var result = confirm("Plan of  " + val + " Confirm To Delete?");
    if (result) {
      axios.delete(`http://45.127.6.59/api/users/newplan/${val}`, {
        params: {
          username: user["username"],
        },
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error}
      <br></br>
      <Card>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SuiTypography variant="h6">SUBSCRIPTION</SuiTypography>
        </SuiBox>
        <SuiBox customClass={classes.tables_table}>
          <Table columns={columns} rows={rows} />
        </SuiBox>

        <SuiBox customClass={classes.tables_table}>
          <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
            <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
              <table className="MuiTable-root css-w793m5-MuiTable-root">
                <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                  <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      SUBSCRIBERS
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      PLAN
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      SPEC
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      EXPIRE
                    </th>
                    <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                      DELETE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {planlist.map((dlak, index) => {
                    return (
                      <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root" key={index}>
                        <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                          <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                            <SuiBox mr={2}>
                              <SuiAvatar src={sub} alt={"name"} size="sm" variant="rounded" />
                            </SuiBox>
                            <SuiBox display="flex" flexDirection="column">
                              <SuiTypography variant="button" fontWeight="medium">
                                {dlak.parent}
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </td>
                        <td>
                          <SuiBadge
                            variant="gradient"
                            badgeContent={dlak.plan}
                            color="primary"
                            size="extra-small"
                          />
                        </td>
                        <td>
                          <SuiBox display="flex" flexDirection="column">
                            <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                              {dlak.spec}
                              <br></br>
                            </SuiTypography>
                            <SuiTypography variant="caption" textColor="secondary">
                              Price : 2500 $
                            </SuiTypography>
                          </SuiBox>
                        </td>

                        <td>
                          <SuiTypography
                            variant="caption"
                            textColor="secondary"
                            fontWeight="medium"
                          >
                            {" "}
                            27/05/1990
                          </SuiTypography>
                        </td>
                        <td align="center">
                          <Icon
                            className="cursor-pointer"
                            color="error"
                            fontSize="medium"
                            onClick={() => deletel_row_plan(dlak.parent)}
                          >
                            delete
                          </Icon>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </SuiBox>
      </Card>
      <br></br>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={7}>
            {/* {type(datchild)} */}
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">SUB USERS</SuiTypography>
              </SuiBox>
              <SuiBox customClass={classes.tables_table}>
                <Table columns={columns} rows={rows} />
              </SuiBox>

              <SuiBox customClass={classes.tables_table}>
                <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                  <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                    <table className="MuiTable-root css-w793m5-MuiTable-root">
                      <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                        <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            CHILD
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            PLAN
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            SPEC
                          </th>
                          {/* <th className='makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw'>EXPIRE</th> */}
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            DELETE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {datchild.map((dlak, index) => {
                          return (
                            <tr
                              className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                              key={index}
                            >
                              <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                  <SuiBox mr={2}>
                                    <SuiAvatar
                                      src={userimage}
                                      alt={"name"}
                                      size="sm"
                                      variant="rounded"
                                    />
                                  </SuiBox>
                                  <SuiBox display="flex" flexDirection="column">
                                    <SuiTypography variant="button" fontWeight="medium">
                                      {dlak.child}
                                    </SuiTypography>
                                    <SuiTypography variant="caption" textColor="secondary">
                                      parent :{dlak.parent}
                                    </SuiTypography>
                                  </SuiBox>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiBadge
                                  variant="gradient"
                                  badgeContent={dlak.plan}
                                  color="primary"
                                  size="extra-small"
                                />
                              </td>
                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    {dlak.spec}
                                    <br></br>
                                  </SuiTypography>
                                  <SuiTypography variant="caption" textColor="secondary">
                                    Price : 200 $
                                  </SuiTypography>
                                </SuiBox>
                              </td>

                              {/* <td><SuiTypography variant="caption" textColor="secondary" fontWeight="medium"> 27/05/1990</SuiTypography></td> */}
                              <td align="center">
                                <Icon
                                  className="cursor-pointer"
                                  color="error"
                                  fontSize="medium"
                                  onClick={() => deletel_row_df(dlak.child)}
                                >
                                  delete
                                </Icon>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </SuiBox>
            </Card>
          </Grid>

          <br></br>
          <Grid item xs={12} md={6} xl={5}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">PLANS</SuiTypography>
              </SuiBox>
              <SuiBox customClass={classes.tables_table}>
                <Table columns={columns} rows={rows} />
              </SuiBox>

              <SuiBox customClass={classes.tables_table}>
                <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                  <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                    <table className="MuiTable-root css-w793m5-MuiTable-root">
                      <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                        <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            PLAN
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            PRICE
                          </th>
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            SPEC
                          </th>
                          {/* <th className='makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw'>EXPIRE</th> */}
                          <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                            DELETE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {newplanlist.map((dlak, index) => {
                          return (
                            <tr
                              className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                              key={index}
                            >
                              <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                  <SuiBox mr={2}>
                                    <SuiAvatar
                                      src={image}
                                      alt={"name"}
                                      size="sm"
                                      variant="rounded"
                                    />
                                  </SuiBox>
                                  <SuiBox display="flex" flexDirection="column">
                                    <SuiTypography variant="button" fontWeight="medium">
                                      {dlak.planname}
                                    </SuiTypography>
                                  </SuiBox>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    {dlak.price}
                                  </SuiTypography>
                                </SuiBox>
                              </td>
                              {/* <td><SuiBadge variant="gradient" badgeContent={dlak.price} color="primary" size="extra-small" /></td> */}
                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    {dlak.specs}
                                  </SuiTypography>
                                </SuiBox>
                              </td>

                              {/* <td><SuiTypography variant="caption" textColor="secondary" fontWeight="medium"> 27/05/1990</SuiTypography></td> */}
                              <td align="center">
                                <Icon
                                  className="cursor-pointer"
                                  color="error"
                                  fontSize="medium"
                                  onClick={() => deletel_row_nplan(dlak.planname)}
                                >
                                  delete
                                </Icon>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={3}>
            <br></br>
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="100%"
              width="100%"
              backgroundColor="#000"
              p={3}
            >
              <SuiTypography variant="button" textColor="white" fontWeight="large">
                ADD USER
              </SuiTypography>
              <div>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="caption" fontWeight="medium" textColor="white">
                    parent user : {user["username"]}
                  </SuiTypography>
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  {
                    <select
                      name="dtcol"
                      id="dtcol"
                      style={{
                        width: "100%",
                        height: 40,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setSelect(event.target.value);
                      }}
                    >
                      <option value="">--CHILD USER--</option>
                      {datalist.map((dlak, index) => {
                        return (
                          <option value={dlak.username} key={index}>
                            {dlak.username}
                          </option>
                        );
                      })}
                    </select>
                  }
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => addchild()}
                    fullWidth
                  >
                    ADD USER
                  </SuiButton>
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  {select && (
                    <SuiTypography variant="caption" textColor="text">
                      child user : {select}
                    </SuiTypography>
                  )}
                </SuiBox>
              </div>
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <br></br>
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="100%"
              width="100%"
              backgroundColor="#000"
              p={3}
            >
              <SuiTypography variant="button" textColor="white" fontWeight="large">
                PLANS
              </SuiTypography>
              <div>
                <SuiBox mb={1} ml={0.5}>
                  {
                    <select
                      name="dtcol"
                      id="dtcol"
                      style={{
                        width: "100%",
                        height: 40,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setSelectPuser(event.target.value);
                      }}
                    >
                      <option value="">--USER--</option>
                      <option value={user["username"]}>{user["username"]}</option>
                      {datalist.map((dlak, index) => {
                        return (
                          <option value={dlak.username} key={index}>
                            {dlak.username}
                          </option>
                        );
                      })}
                    </select>
                  }
                </SuiBox>

                <SuiBox mb={1} ml={0.5}>
                  {
                    <select
                      name="dtcol"
                      id="dtcol"
                      style={{
                        width: "100%",
                        height: 40,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setSelectPlan(event.target.value);
                      }}
                    >
                      <option value="">--SELECT PLAN--</option>
                      {newplanlist.map((dlak, index) => {
                        return (
                          <option value={dlak.planname} key={index}>
                            {dlak.planname}
                          </option>
                        );
                      })}
                    </select>
                  }
                </SuiBox>

                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => addplan()}
                    fullWidth
                  >
                    ADD PLAN
                  </SuiButton>
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  {selectplan && (
                    <SuiTypography variant="caption" textColor="text">
                      plan : {selectplan}
                    </SuiTypography>
                  )}
                  <br></br>
                  {selectpuser && (
                    <SuiTypography variant="caption" textColor="text">
                      user : {selectpuser}
                    </SuiTypography>
                  )}
                </SuiBox>
              </div>
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <br></br>
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="100%"
              width="100%"
              backgroundColor="#000"
              p={3}
            >
              <SuiTypography variant="button" textColor="white" fontWeight="large">
                ADD NEW PLAN
              </SuiTypography>
              <div>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={planname}
                    onChange={(event) => {
                      setPlanName(event.target.value);
                    }}
                    type="text"
                    placeholder="Plan Name"
                  />
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={spec}
                    onChange={(event) => {
                      setSpec(event.target.value);
                    }}
                    type="text"
                    placeholder="Specs Eg:Limit :1,Space:1GB"
                  />
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={price}
                    onChange={(event) => {
                      setPrice(event.target.value);
                    }}
                    type="text"
                    placeholder="Price"
                  />
                </SuiBox>

                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => addnewplan()}
                    fullWidth
                  >
                    ADD NEW
                  </SuiButton>
                </SuiBox>
              </div>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <br></br>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
