import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Invoice from "layouts/s3folder/components/Invoice";
// import PlatformSettings from "layouts/profile/components/ColumnsSettings";
import SuiButton from "components/SuiButton";
import { useEffect, useState } from "react";
import axios from "axios";
import Icon from "@mui/material/Icon";
import styles from "layouts/tables/styles";
import { useAuth } from "auth-context/auth.context";
// const params = new URLSearchParams(document.location.search);
function Overview(outlined) {
  const classes = styles();
  const currentUrl = window.location.href;
  const folder = currentUrl.split("?")[1].split("=")[1];
  const [s3file, setS3File] = useState([]);
  const [num, setNum] = useState(0);
  let { user } = useAuth();
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  const [file, setFile] = useState("");
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  const newFile = () => {
    const uploadData = new FormData();
    if (file == "") {
      setMes(undefined);
      return setError("You must Select File");
    } else {
      uploadData.append("user", user["username"]);
      try {
        for (let i = 0; i < file.length; i++) {
          console.log(file[i]);
          uploadData.append(file[i].name, file[i], file[i].name);
        }
        uploadData.append("folder", folder);

        fetch("http://45.127.6.59/api/users/s3file", {
          method: "POST",
          body: uploadData,
        })
          .then((res) =>
            res.json().then((data) => {
              console.log(data);
              if (data.status == 1) {
                {
                  setError("");
                }
              }
              {
                setMes("Successfully Uploaded");
                decrementNum();
              }
              if (data.status == -1) {
                {
                  setError("Unknown Error");
                }
                {
                  setMes("");
                }
              }
            })
          )

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }

    setError(undefined);
    decrementNum();

    return setMes(mes);
  };
  useEffect(() => {
    if (num == 0) {
      axios
        .get(`http://45.127.6.59/api/users/s3folderfile/${folder}`, {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setS3File(res.data));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={8}>
            <Card id="delete-account">
              <SuiBox
                pt={2}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  {folder} Latest Uploaded
                </SuiTypography>
                <SuiButton variant="outlined" buttonColor="info" size="small">
                  view all
                </SuiButton>
              </SuiBox>
              <SuiBox p={2}>
                <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  {s3file.map((s3, index) => {
                    return (
                      <div key={index}>
                        <Invoice
                          date={s3.fname}
                          id={s3.date}
                          price={s3.size + " bytes"}
                          movdlake={
                            <a href={"/#/s3toweb/?s3=" + s3.s3link}>
                              <Icon fontSize="large" color="primary">
                                save
                              </Icon>
                            </a>
                          }
                          download={
                            <a href={s3.s3link}>
                              <Icon fontSize="large" color="success">
                                download
                              </Icon>
                            </a>
                          }
                          pk={s3.fid}
                        />
                      </div>
                    );
                  })}
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Card
              raised
              className={clsx(classes.placeholderCard, {
                [classes.placeholderCard_outlined]: outlined,
              })}
            >
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                p={3}
              >
                <div>
                  <SuiBox mb={1} ml={0.5}>
                    <input
                      id="file"
                      multiple="multiple"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(evt) => setFile(evt.target.files)}
                    />
                    <label htmlFor="file">
                      <Button variant="contained" color="primary" component="span" fullWidth>
                        <Icon className="font-bold">add</Icon>
                        &nbsp; add file
                      </Button>
                    </label>

                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "grey", textAlign: "center" }}>
                        {file && <p>{"Selected " + file.length + " Files"}</p>}
                      </h6>
                    </SuiBox>
                  </SuiBox>

                  <SuiBox mb={1} ml={0.5}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="dark"
                      onClick={() => newFile()}
                      fullWidth
                    >
                      upload
                    </SuiButton>
                  </SuiBox>
                </div>
                {error && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                  </SuiBox>
                )}
                {mes && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                  </SuiBox>
                )}
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
